import AppleIcon from "@res/apple-app-store-badge.png";
import GoogleIcon from "@res/google-play-badge.png";
import translate from '@translate';
import React from 'react';

export default function AppLinks({styles}) {

    return (
        <div style={styles.cardContainerApps}>
            <p style={styles.titleLinks}>{translate("download-card-title")}</p>
            <div style={styles.iconContainer}>
                <a href="https://apps.apple.com/us/app/lubbock-isd/id617521876" style={{display: "flex", justifyContent: "center"}}>
                    <img style={styles.appleIcon} src={AppleIcon} alt="fireSpot"/>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.lisd.tab&hl=en_US&gl=US" style={{display: "flex", justifyContent: "center"}}>
                    <img style={styles.googleIcon} src={GoogleIcon} alt="fireSpot"/>
                </a>
            </div>
            <p style={styles.textLinks}>{translate("download-card-paragraph")}</p>
        </div>
    )
}
