import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: '', errorStack: '' };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);

    // Update state with error details to display
    this.setState({
      errorMessage: error.toString(),
      errorStack: errorInfo.componentStack
    });
  }

  render() {
    if (this.state.hasError) {
      // Check if running in development mode
      const isDevelopment = process.env.NODE_ENV === 'development';

      return (
        <div>
          <h1>Something went wrong.</h1>
          {/* {isDevelopment && ( */}
            <details style={{ whiteSpace: 'pre-wrap' }}>
              <summary>Click for error details</summary>
              {this.state.errorMessage && <p>{this.state.errorMessage}</p>}
              {this.state.errorStack && <p>{this.state.errorStack}</p>}
            </details>
          {/* )}
          {!isDevelopment && <p>Sorry, something went wrong. Please try again later.</p>} */}
        </div>
      );
    }

    return this.props.children;
  }
}

export const ErrorBoundaryExport = ErrorBoundary;
