import Ticker from "@components/ticker";
import { Context } from '@context';
import { Button } from '@mui/material';
import translate from "@translate";
import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Carousel from "../subcomponents/carousel";
import StudentList from '../subcomponents/students-list';
import styles from './styles';

export default function DashboardMobile() {
    const { futureEventsFiltered, students, rewards, parent } = useContext(Context);
    const navigate = useNavigate();

    return (
        <div style={{width: "97%"}}>
            {parent && 
            <Ticker 
                count={parent.totalCredits - parent.creditsRedeemed}
                text={translate("total available credits")}
            />}
            <div style={styles.buttonContainer}>
                <Button variant="contained" style={styles.button} color="primary" onClick={() => navigate("/home/event-list")}>{translate("CHECK IN")}</Button>
                <Button variant="contained" style={styles.button} color="secondary" onClick={() => navigate("/home/credits")}>{translate("REDEEM POINTS")}</Button>
            </div>
            <Carousel type={"events"} data={futureEventsFiltered}/>
            <Carousel type={"rewards"} data={rewards}/>
            <StudentList students={students}/>
        </div>
    )
}
