import React, { useContext } from "react";
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";

import translate from '@translate';
import { Context } from '@context';
import { useStyles, styles } from './calendarStyles'
import { DetailListItem } from './DetailListItem'


export const CalendarDayDetail = ({checked, setChecked, selectedDate, setSelectedDate, setEventsForDate}) => {
    //console.log('selectedDate', selectedDate);
    const { locale } = useContext(Context)

    const classes = useStyles();
    const theme = useTheme();

    const showMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        setChecked(!checked)
    };

    const handleDateBack = () => {
        const yesterday = new Date(selectedDate.date)
        yesterday.setDate(yesterday.getDate() - 1)
        setEventsForDate(yesterday, "change")
    }

    const handleDateForward = () => {
        const tomorrow = new Date(selectedDate.date)
        tomorrow.setDate(tomorrow.getDate() + 1)
        setEventsForDate(tomorrow, "change")
    }
    
    const handleDate = (date, option) => {
        const dateOptions = [{ month: 'long', day: 'numeric' }, { month: 'short', day: 'numeric' }];
        let selection = new Date(date.date)
        return selection.toLocaleDateString(locale, dateOptions[option])
    }

    return (
        <div className={classes.calendarDetailContainer}>
            <div className={classes.detailViewButton}>
                <Typography variant={showMobile ? "body1" : "h6"} color="primary" sx={styles.viewButton} onClick={()=>{handleClose()}}>{translate("VIEW CALENDAR")}</Typography>
            </div>
            <div className={classes.controlContainer}>
                <ArrowBack className={classes.arrowHover} color="secondary" fontSize={showMobile ? "medium" : "large"} onClick={()=>{handleDateBack()}} />
                <Typography variant={showMobile ? "h4" : "h3"} color="primary" sx={styles.title}>{handleDate(selectedDate, 0)}</Typography>
                <ArrowForward className={classes.arrowHover} color="secondary" fontSize={showMobile ? "medium" : "large"} onClick={()=>{handleDateForward()}} />
            </div>
            <div className={classes.eventListContainer}>
                {selectedDate.events.length ? selectedDate.events.map((event) => {
                    return <DetailListItem event={event} handleDate={handleDate} selectedDate={selectedDate} />
                }) :
                <div className={classes.noEventContainer}>
                <Typography variant={showMobile ? "h6" : "h4"} color="secondary" sx={styles.title}>
                    {/* {handleDate(selectedDate, 0)} */}
                    {translate("noEvents")}
                    </Typography>
                </div>
                }
            </div>
        </div>
    )
  };