import { LOCALES } from "../locales";

export default {
  [LOCALES.ENGLISH]:{
    'login': 'LOGIN',
    'singUp': 'SIGN UP',
    'logout': 'log out',
    'menu': "MENU",
    'filters': 'filters',
    'resetFilters': 'Reset Filters',
    'events': "events",
    'allEvents': 'All Events',
    'achievedEvents': 'Achieved Events',
    'archivedevents': 'Archived events',
    'upcomingEvents': 'Upcoming Events',
    'addNewEvent': 'Add New Event',
    'searchEvents': 'Search Events',
    'eventName': 'Event Name',
    'uploadCSV': 'Upload CSV',
    'schoolName': 'School Name',
    "download-card-title": "DOWNLOAD THE LISD APP",
    "download-card-paragraph": "Download the official Lubbock ISD app - your official source for timely news and information. Together, with your LISD Parent University app, you’ll have all the information you need at your fingertips!",
    "rewards": "rewards",
    "view": "view",
    "view events": "VIEW EVENTS",
    "view rewards": "VIEW REWARDS",
    "VIEW CALENDAR": "VIEW CALENDAR",
    "redeem now": "redeem now",
    "redeem": "redeem",
    "credits": "credits",
    "Credits": "Credits",
    "dashboard": "dashboard",
    "calendar": "calendar",
    "parents": "parents",
    "resources": "resources",
    "options": "options",
    "notes": "notes",
    'addANote': 'Add a Note',
    "account": "account",
    "gradebook": "gradebook",
    'students': 'students',
    "credits": "credits",
    "unlocked": "unlocked",
    "locked": "locked",
    "start time": "start time",
    "end time": "end time",
    "total available credits": "total available credits",
    'totalCreditsRedeemed': 'Total credits redeemed',
    "VIEW CREDIT HISTORY": "VIEW CREDIT HISTORY",
    "Credit History": "Credit History",
    "CHECK IN": "CHECK IN",
    "REDEEM POINTS": "REDEEM POINTS",
    "TOTAL AVAILABLE CREDITS": "TOTAL AVAILABLE CREDITS",
    "REDEEM CREDITS": "REDEEM CREDITS",
    "REDEEM": "REDEEM",
    "Event Details": "Event Details",
    'email': 'Email',
    'password': 'Password',
    'signIn': 'Sign in',
    'accountDescription': "Don't have a gradebook account set one up here",
    'changeLanguage': 'Change Language',
    'dataUpdateMidnight': 'Data updated every day at midnight',
    'schools': 'Schools',
    'eventCategories': 'Event Categories',
    'eategories': 'Categories',
    'academic': 'Academic',
    'athletic': 'Athletic',
    'gradeLevel': 'Grade Level',
    'allCampus': 'All Campus',
    'resetFilters': 'Reset filters',
    'aboutToUse': 'You are about to use',
    'forThisItem': "for this item", 
    'eventList': 'Event List',
    'january': 'January',
    'february': 'February',
    'march': 'March',
    'april': 'April',
    'may': 'May',
    'june': 'June',
    'july': 'July',
    'august': 'August',
    'september': 'September',
    'october': 'October',
    'november': 'November',
    'december': 'December',
    'today': 'Today',
    'resetFilters': 'Reset Filters',
    'viewMore': 'View more',
    'calendar': 'Calendar',
    'selectDate': 'Select date for event details',
    'monday': 'Monday',
    'mMonday': 'M',
    'tuesday': 'Tuesday',
    'tTuesday': 'T',
    'wednesday': 'Wednesday',
    'wWednesday': 'W',
    'thursday': 'Thursday',
    'tThursday': 'T',
    'friday': 'Friday',
    'fFriday': 'F',
    'saturday': 'Saturday',
    'sSaturday': 'S',
    'sunday': 'Sunday',
    'sSunday': 'S',
    'noteSubject': 'Note Subject',
    'Event Details': 'Event Details',
    'onlineEvent': 'online event',
    'inPersonEvent': 'In person event',
    'thankYouForCheckIn': 'Thank you for checking in!',
    'english': 'English',
    'spanish': 'Spanish',
    'manageEvents': 'Manage Events',
    'manageRewards': 'Manage Rewards',
    'manageResources': 'Manage Resources',
    'eventDate': 'Event Date',
    'addReward': 'Add Reward',
    'nameOfReward': 'Name of Reward',
    'auantity': 'Quantity',
    'pointsToRedeem': 'Credits Left',
    'description': 'Description',
    'urlOfThePhoto': 'URL of the photo',
    'save': 'Save',
    'addNewResource': 'Add new resource',
    'resourceName': 'Resource Name',
    'resourceLink': 'Resource Link',
    'parentCreditHistory': 'Parent Credit History',
    'totalParents': 'Total Parents',
    'filtersBy': 'Filters By',
    'parentName': 'Parent Name',
    'totalCredits': 'total Credits',
    'viewHistory': 'View History',
    'backToParents': 'Back to parents',
    'eventRequestCredit': "Don't see an event? Request credit(s)",
    'requestCredit': "Request credit(s)",
    'edit': 'Edit',
    'delete': 'Delete',
    'creditsToRedeem': 'Credits to redeem',
    'saveChanges': 'Save changes',
    'deleteEvent': 'Delete event',
    'uploadImage': 'Upload image',
    'eventDescription': 'Event description',
    'eventStart': 'Event start',
    'eventEnd': 'Event end',
    'eventLatitude': 'Event latitude',
    'eventLongitude': 'Event longitude',
    'eventCategory': 'Event category',
    'points': 'Credits',
    'aboutToDelete': 'You are about to delete',
    'actionUndone': 'This action cannot be undone',
    'likeToContinue': 'Would you like to continue?',
    'yes': 'Yes',
    'no': 'No',
    'downloadApp': 'Download the LISD app',
    'noEvents': 'there are no events on this date',
    "Get Directions": "Get Directions",
    "welcome": "welcome",
    "calendarDescription": "The Calendar is your source for important date-specific school events for your student(s). Click on the date to see a list of events, color-coded by student for your convenience.",
    "redeemedReward": "You’ve redeemed a reward through Parent University!",
    "checkEmail": "Check your email for your reward redemption receipt.",
    "pickUpReward": "Your reward will be available for pick up in 7 business days in the school office.",
    "mustHaveReciept": "You must have your reward redemption receipt to claim your reward.",
    'forgotpassword': 'Forgot Password',
    'resetpassword': 'Reset Password',
    'resetdescription': "Enter your email address and we'll send you a link to reset your password.",
    'emailaddress': 'Email Address',
    'submit': 'SUBMIT',
    'returntologin': 'Return to Login',
    'changepassword': 'Change Password',
    'announcements': 'announcements',
    'supportTextLine1': 'Your ParentU email address is the same email address that you use for Gradebook.',
    'supportTextLine2': 'for Gradebook help or contact your campus front office.',
    'supportTextLine3': 'Click the Forgot Password link above to set or reset your password for Parent U.',
    'clickHere': 'Click Here',
    'Community': 'Community',
    'Academic': 'Academic',
    'General':'General',
    'Fine Arts': 'Fine Arts',
    'Campus': 'Campus',
    'WEBSITE': 'WEBSITE',
    'Address': "Address",
    'descriptionRequired': "Description is required",
    'required': 'Required',
    'Credits are required' : 'Credits are required',
    "ViewRequest" : "View Request",
    "Submit Request" : "Submit Request",
    "View More": "View More",
    "Redeemed": "Redeemed",
    "Cancel": "Cancel",
    "Removing...": "Removing...",
    "Remove": "Remove",
    "Are you sure you want to remove this note?": "Are you sure you want to remove this note?",
    "Eliminar nota": "Eliminar nota",
    "Add a Note": "Add a Note",
    "Subject": "Subject",
    "Remove Note": "Remove Note",
    "Available Credits": "Available Credits",
    "Redeemed Credits": "Redeemed Credits",
    "Date": "Date",
    "Name": "Name",
    "VIEW CREDITS":   "VIEW CREDITS",
    "Athletic": "Athletic",
    "Sports": "Sports",
    "District": "District",
    "Redeem Again": "Redeem Again",
    "descriptionTitle": "Description: ",
    "Lock": "Lock"

    

    
    
  }
}