import { PRIMARY, SECONDARY } from '@constraints';

export default {
    cardContainer: {
        borderWidth: 1,
        borderRadius: 2,
        backgroundColor: "#fff",
        width: 325,
        height: 440,
        marginRight: 20,
        boxShadow: "0.5px 0px 1.5px 1px lightgray",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    cardContainerApps: {
        borderWidth: 1,
        borderRadius: 2,
        backgroundColor: "#fff",
        width: 325,
        height: 440,
        marginRight: 20,
        boxShadow: "0.5px 0px 1.5px 1px lightgray",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center"
    },
    cardContainerRewards: {
        borderWidth: 1,
        borderRadius: 2,
        backgroundColor: "#fff",
        width: 325,
        height: 440,
        marginRight: 20,
        boxShadow: "0.5px 0px 1.5px 1px lightgray",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    eventImageContainer: {
        height: 165,
        width: "auto",
    },
    iconContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "35%",
        paddingTop: 50,
        paddingBottom: 50,
    },
    iconContainerRewards: {
        height: "100%",
        width: "100%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // marginBottom: 20
    },
    appleIcon: {
        width: "auto",
        height: "auto",
        maxWidth: "95%",
        position: "relative",
        top: 20
    },
    googleIcon: {
        width: "auto",
        height: "auto",
        maxWidth: "95%",
        position: "relative",
        bottom: 10
    },
    titleLinks: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        color: SECONDARY
    },
    textLinks: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 10,
        color: SECONDARY,
        fontSize: 15
    },
    titleEvents: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        height: "1%",
        color: PRIMARY,
        fontSize: 20,
        textTransform: "uppercase",
    },
    titleRewards: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        height: "1%",
        color: SECONDARY,
        fontSize: 20,
        textTransform: "uppercase",
    },
    viewEventsButtonContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 100,
    },
    viewRewardsButtonContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 100,
    },
    redeemButtonContainer: {
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
    },
    redeemButton: {
        width: "100%",
        height: 45,
        maxWidth: "100%",
    },
    rewardContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "stretch",
        height: "75%",
        width: "90%",
        padding:0,
    },
    rewardImage: {
        width: "auto",
        maxWidth: '100%',
        maxHeight: 160,
        // objectFit: "cover",
    },
    // image: {
    //     // width: size === "lg" ? "55%" : "70%",
    //     // height: "auto",
    //     objectFit: "cover",
    //     // maxWidth: 10,
    //     maxWidth: size === "lg" ? "55%" : "60%",
    //     maxHeight: size === "lg" ? "21vh" : size ==="md" ? "24vh" : "24vh",
    // },
    // imageContainer: {
    //     width: "auto",
    //     height: size === "lg" ? "21vh" : size ==="md" ? "24vh" : "24vh",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     border: `2px solid ${FADEDGRAY}`,
    //     borderBottom: 0,
    //     overflow: 'hidden'
    // },
    rewardInfoContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        // height: 20,
    },
    rewardText: {
        color: PRIMARY,
        fontSize: 14,
        lineHeight: 1,
        margin: 1,

      },
    rewardTextBold: {
        color: PRIMARY,
        fontWeight: "bold",
        fontSize: 14,
        //lineHeight: 0.5,
        marginRight: 4,
        textTransform: "uppercase",
    },
    eventContentContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "stretch",
        height: "100%",
        width: "90%",
        padding:0,
        paddingBottom: 'auto'
    },
    eventImage: {
        // height: 175,
        width: "100%",
        maxHeight: "100%",
        objectFit: "cover",
    },
    eventInfoContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
    },
    eventDate: {
        lineHeight: 0.5,
        fontWeight: "bold",
        color: PRIMARY,
        fontSize: 16
    },
    eventName: {
        lineHeight: 1,
        fontSize: 16,
        color: SECONDARY,
    },
    eventSchool: {
        lineHeight: 1,
        color: SECONDARY,
        fontSize: 14,
    },
    eventTime: {
        lineHeight: 0.5,
        color: SECONDARY,
        fontSize: 14,
        textTransform: "uppercase",
    },
    creditsCircleContainer: {
        width: '35%',
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
        alignItems: 'center',
        // position: 'relative',
        // bottom: 0,
    },
    creditNumber: {
        marginTop: 5, 
        fontSize: 14,
        color: SECONDARY,
        textTransform: "uppercase",
        marginRight: 4
    },
    creditText: {
        marginTop: 5, 
        fontSize: 14,
        color: SECONDARY,
        textTransform: "uppercase",
    },
    dotRow: {
        // display: "flex",
        // justifyContent: "space-around"
    },
    buttonPrimary: {
        borderRadius: 0,
        border: `2px solud red`
    },
    buttonSecondary: {
        borderRadius: 0,
        border: `2px solud ${SECONDARY}`
    },
}