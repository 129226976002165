
import { Button, Typography } from '@mui/material';
import styles from './styles';
import { PasswordReset } from '../../components/password-reset'
import mainLogo from'../../res/logo.png';


export const ChangePassword = () => {

  return (
    <div style={styles.mainContainer}>
      <img style={styles.logo} src={mainLogo} alt="fireSpot"/>
      <div style={styles.formContainer}>
        <PasswordReset/>
      </div>
    </div>
  );
};