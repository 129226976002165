import React, { useState, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { useLocation } from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from '@mui/material/styles';
import { Context } from "../../context";
import translate from '../../i18n/translate';

import { MobileDrawerMenu } from '../navigation/navMenu/MobileDrawerMenu';
import { MobileFilterMenu } from '../navigation/fliterMenu/MobileFilterMenu';

export default function ButtonAppBar({setLanguageCookie}) {
    const { setMobileMenuOpen, setMobileFilterOpen, navList, titles } = useContext(Context);

    const theme = useTheme();
    const location = useLocation();

    const checkActiveLink = () => {
        const currentPage = navList.filter(item => item.link === location.pathname);
        if(currentPage.length > 0) {
            return translate(currentPage[0].name)
        }
        else if(location?.pathname.includes("event-details")) {
            return translate("Event Details");
        }
        else if(location?.pathname.includes("credit-history")) {
            return translate("Credit History");
        } else {
            return "No Page Name to Translate";
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
        <AppBar
            position="static"
            color='neutral'
            elevation={0}
            >
            <Toolbar>
            <IconButton
                size="large"
                edge="start"
                color="secondary"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={()=> {
                    // console.log("filter open");
                    setTimeout(()=>{setMobileMenuOpen(true)},200);
                    }
                }
            >
                <MenuIcon />
            </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center', color: theme.palette.secondary.main, fontFamily: 'Poppins', textTransform: 'capitalize' }}>
                    {checkActiveLink()}
                </Typography>
            <IconButton
                size="large"
                edge="end"
                color="secondary"
                aria-label="menu"
                sx={{ ml: 2 }}
                onClick={()=> {
                    // console.log("filter open");
                    setTimeout(()=>{setMobileFilterOpen(true)},200);
                    }
                }
            >
                <MoreVertIcon />
            </IconButton>
            </Toolbar>
        </AppBar>
        <MobileDrawerMenu />
        <MobileFilterMenu />
        </Box>
    );
}