
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import { getAuth, signOut } from "firebase/auth";
import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../../context";
import translate from '../../../i18n/translate';
import { theme } from '../../../utils/theme';
import { NavFooter } from '../navFooter';
//import { LoginReward } from '../../redeem-reward-points/loginReward';


const useStyles = makeStyles(theme => ({
    menuTitleContainer: {
        width: "100%",
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    menuTitle: { flex: 1,
        textAlign: 'center',
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontFamily: 'Poppins'
    },
    navContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: "column",
        justifyContent: "space-between",
    },
    navFooter: {
        paddingBottom: '10vh',
    }
  }));
  

  const style = {
      root: {
        color: theme.palette.secondary.main,
        paddingLeft: 1,
        fontFamily: 'Poppins',
        textTransform: 'capitalize'
    }
  }

export const MobileDrawerMenu = () => {
    const { mobileMenuOpen, setMobileMenuOpen, navList, language, setLanguage, setLocale, setStudents, setParent,showRewards, setShowRewards } = useContext(Context);

    const theme = useTheme();
    const classes = useStyles();
    const auth = getAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const showOnTabletVert = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        setShowRewards(true);
       }, [showRewards]);
      // console.log("showRewards",showRewards)

    const onSignout = () => {
        signOut(auth)
        .then(() => {
          // Sign-out successful.
          setMobileMenuOpen(false);
          setStudents([]);
          setParent(null);
          navigate("/");
        }).catch((error) => {
          // An error happened.
        });
      }

    const navigateTo = (link) => {
        setMobileMenuOpen(false);
        setTimeout(()=> {
            navigate(link);
        }, 250)
    }

    const checkActiveLink = (item) => {
        if (item.link === location.pathname) {
            return {
                color: theme.palette.primary.main,
                fontWeight: 'bold',
                fontSize: 18,
                paddingLeft: 1,
                fontFamily: 'Poppins',
                textTransform: 'capitalize'
            }
        } else {
            return {
                color: theme.palette.secondary.main,
                paddingLeft: 1,
                fontFamily: 'Poppins',
                textTransform: 'capitalize'
            }
        }
    }
    
    const handleChange = (event) => {
        setLanguage(event.target.value);
        setLocale(event.target.value);
    };
    
    return (
        <>      
            <SwipeableDrawer
                anchor={'left'}
                open={mobileMenuOpen}
                disableSwipeToOpen={true}
                onClose={()=>setMobileMenuOpen(false)}
                onOpen={()=>setMobileMenuOpen(true)}
                disableDiscovery={true}
            >
                <div className={classes.navContainer}>
                    <Box
                    sx={showOnTabletVert ? { width: '35vw' } :  { width: '55vw' }}
                    role="presentation"
                    >
                        <div className={classes.menuTitleContainer}>
                            <Typography variant="h6" component="div" className={classes.menuTitle}>
                            {translate('menu')}
                            </Typography>
                        </div>
                        <List>
                            {navList.map((text, index) => (
                                <ListItem button key={text.name} onClick={()=>navigateTo(text.link)}  sx={{ cursor: 'pointer' }}>
                                    <ListItemText disableTypography={true} primary={translate(text.name)} sx={checkActiveLink(text)} />
                                </ListItem>
                            ))}
                            <ListItem button key={translate('logout')} onClick={onSignout}  sx={{ cursor: 'pointer' }}>
                                <ListItemText disableTypography={true} primary={translate('logout')} sx={style.root} />
                            </ListItem>
                        </List>
                        <Box sx={{ width: '45%', paddingLeft: 3, }}>
                            <FormControl fullWidth variant="standard">
                                <Select
                                id="language-select"
                                value={language}
                                onChange={handleChange}
                                disableUnderline
                                style={{color: theme.palette.secondary.main, fontWeight: 400, fontSize: '0.9rem', fontFamily: "Poppins"}}
                                sx={{color: theme.palette.primary.main,"& .MuiSvgIcon-root": {color: theme.palette.secondary.main,}}}
                                >
                                <MenuItem value={'en-us'}>{translate('english')}</MenuItem>
                                <MenuItem value={'es-es'}>{translate('spanish')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <>
                        <div className={classes.navFooter}>
                            <NavFooter />
                        </div>
                        {/* <div className={classes.menuTitleContainer}>
                            <Button variant="contained" style={{ maxWidth: '80%' }} onClick={() => console.log("Gradebook")}>{translate('gradebook')}</Button>
                        </div> */}
                    </>
                </div>
            </SwipeableDrawer>
            </>

    );
}