import { Box, Grid } from '@mui/material';
import { useNavigate } from "react-router-dom";
import EventCard from "./EventCard";

export default function EventsGrid({ events, size='lg' }) {
  // const navigate = useNavigate();
  const styles = {
      box: {
          paddingLeft: size === "lg" ? "2%" : "2.5%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          width: "100%"
      }
  }

  return (
    <Box sx={{ flexGrow: 1 }} style={styles.box}>
      {/* <p>{size}</p> */}
      <Grid container spacing={{ xs: 2, md: 2.2, sm : 2 }} columns={{ xs: 4, sm: 6, md: 5 }}>
        {events.map((event, index) => (
          <Grid item xs={2} sm={3} md={1} key={index}>
            <EventCard event={event}/>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}