export const addEventsToCalendar = (events, setMark) => {
  if (events.length) {
    const formatMarkDate = (date) => {
      // console.log(date);
      return `${(date.getMonth() + 1).toString()}-${date
        .getDate()
        .toString()}-${date.getFullYear()}`;
    };

    let markList = [];
    events.forEach((event) => {
      markList.push(formatMarkDate(new Date(event.date.seconds * 1000)))
    })
    // console.log("CALENDAR MARKS: ", markList)
    // console.log("SECONDS", markList)
    setMark(markList)
  } else {
    setMark([])
  }
}