import { convertDistance, getDistance } from 'geolib';
import { dateToLocaleTimeString, timeToDateString } from ".";

export const validateCheckIn = async (event, userLocation, checkedIn) => {
  if(userLocation.coords.latitude === "" || userLocation.coords.longitude === "") {
    alert('Please enable your location in system preferences and browser settings and allow access permission. Refresh page after changing settings');
    return;
  }
  // console.log('userLocation', userLocation);
  //GET CURRENT TIME IN MILLISECONDS
  const now = new Date().getTime();

  //CONVERTS START AND END TIME INTO A DATE, CONVERTS TO MILLISECONDS
  //AND THEN ADDS OR SUBTRACTS AND HOUR'S WORTH OF MILLISECONDS
  const hourBeforeStartTime = new Date(timeToDateString(event.startTime)).getTime() - 1 * 60 * 60 * 1000;
  const hourAfterEndTime = new Date(timeToDateString(event.endTime)).getTime() + 1 * 60 * 60 * 1000; 

  const distance = getDistance(
    { latitude: userLocation.coords.latitude, longitude: userLocation.coords.longitude },
    { latitude: parseFloat(event.eventLat), longitude: parseFloat(event.eventLong) }
  )
  // console.log('distance',distance,userLocation.coords);

  //DATE CHECK
  if((Date.parse(dateToLocaleTimeString(now)) !== Date.parse(dateToLocaleTimeString(event.dateString)))) {
    // console.log("DID NOT PASS DATE CHECK")
    return alert("You can't check in, this event is not today.")
  } else {
    console.log("PASSED DATE CHECK")
  }
  //TIME CHECK: "NOW" IN MILLISECONDS MUST BE LESS THAN START TIME IN MILLISECONDS
  //AND GREATER THAN END TIME IN MILLISECONDS TO PASS
  if(now < hourBeforeStartTime || now > hourAfterEndTime) {
    // console.log("DID NOT PASS TIME CHECK")
    if (now < hourBeforeStartTime) {
      return alert("You can't check in yet. Please wait until at least an hour before for the event.");
    }
    if (now > hourAfterEndTime) {
      return alert("You can no longer check in. The event has ended.");
    }
  } else {
    // console.log("PASSED TIME CHECK")
  }
  //DISTANCE CHECK
  if(!event.virtual && convertDistance(distance, 'mi') > 1.0) {
    // console.log("DID NOT PASS DISTANCE CHECK")
    return alert("You are too far away from the event location. Please be within a mile and then try again.");
  } else {
    // console.log("PASSED DISTANCE CHECK")
  }
  //ALREADY CHECKED IN CHECK
  if (checkedIn) {
    return alert('You are already checked in for this event.');
  } else {
    // console.log("PASSED ALREADY CHECKED IN CHECK");
  }

  return true;
};