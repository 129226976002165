import { useContext } from 'react';
import { Route, Routes } from 'react-router';
import { Context } from './context';
import { I18nProvider } from './i18n';
import PrivateRoute from './PrivateRoute';
import { ChangePassword } from './routes/change-password';
import { ForgotPassword } from './routes/forgot-password';
import { Home } from './routes/home';
import { Login } from './routes/login';
export const Router = () => {

  const { locale } = useContext(Context);

  return (
    <I18nProvider locale={locale}>
      {/* <AuthProvider>
        <ContextProvider> */}
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/change-password" element={<ChangePassword />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/home/*"
                element={
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                }
              />
              {/* <Route path='/home/event-details/id=:id' element={<EventDetails/>}/> */}
          </Routes>
          {/* </ContextProvider>
      </AuthProvider> */}
    </I18nProvider>
  );
};
