import React from 'react';
import CreditsCircle from '../student-circle';
// import styles from './styles';

export default function index({student, hasSeperator=true}) {

    const styles = {
        rowContainer: {
            
        },
        cardContainer: {
            display: "flex",
            alignItems: "center",
            marginBottom: 8,
            marginTop: 8,
        },
        creditsCircle: {
            borderRadius: 50,
            backgroundColor: student.color,
        },
        studentName: {
           color: student.color,
           fontSize: 17,
           fontWeight: "bold",
           margin: 1
        },
        studentSchool: {
            color: student.color,
            margin: 1,
            fontSize: 14,
        },
        textContainer: {
            marginLeft: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
        seperator: {
            borderBottom: "1px solid lightgray"
        },
        borderBottom: {
    
        },
        borderTop: {
    
        },
        credits: {
    
        },
    }

    return (
        <div style={styles.rowContainer}>
            <div style={styles.cardContainer}>
                <CreditsCircle 
                    color={student.color}
                    circleSize={40}
                    creditsCountSize={18}
                    textSize={8}
                    credits={student.studentCredits}
                    textVisible={false}
                />
                <div style={styles.textContainer}>
                    <p style={styles.studentName}>{`${student.studentFirstName} ${student.studentLastName}`}</p>
                    <p style={styles.studentSchool}>{student.school}</p>
                </div>
            </div>
            {hasSeperator && <div style={styles.seperator}/>}
        </div>
    )
}

