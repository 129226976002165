import React from 'react';
import { Typography } from '@mui/material';
import {SECONDARY } from '@constraints';
const Announcement = ({item}) => {
  return(
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: 20, borderBottom: "1px solid lightgray", margin: 10, padding: '20px 0px 0px 20px'}}>
      <Typography variant="body1" color={SECONDARY}>{item.title}</Typography>
      <Typography variant="subtitle2" style={{textIndent: '20px'}}>{item.description}</Typography>
      <Typography variant="body2" color={SECONDARY}>School: {item.school === 'Select All' ? 'All Schools' : item.school}</Typography>
      <Typography variant={"body2"} color={SECONDARY}>Date: {item.date.toDate().toDateString()}</Typography>
    </div>
  )

}

export default Announcement;