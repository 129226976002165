import { PRIMARY, SECONDARY } from '@constraints';

export default {

    cardContainer: {
        backgroundColor: "red",
    },
    
    rewardBox: {
        display: "flex",
        justifyContent: "center", 
        alignItems: "center",
        padding: 15,
        marginBottom: 5,
        border: "1px solid lightgray"
    },
    rewardBoxLocked: {
        display: "flex",
        justifyContent: "center", 
        alignItems: "center",
        padding: 15,
        marginBottom: 5,
        border: "3px solid lightgray"
    },
    slideContainer: {
        borderWidth: 1,
        borderRadius: 2,
        backgroundColor: "#fff",
        width: 140,
        height: 160,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: 0.5
    },
    cardContainer: {
        // borderWidth: 1,
        // borderRadius: 2,
        // backgroundColor: "#fff",
        // width: 150,
        // height: 150,
        // boxShadow: "0.5px 0px 1.5px 1px lightgray",
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        // justifyContent: "flex-start"
    },
    iconContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "35%",
        paddingTop: 50,
        paddingBottom: 50,
    },
    appleIcon: {
        width: "auto",
        height: "auto",
        maxWidth: "95%",
        position: "relative",
        top: 20
    },
    googleIcon: {
        width: "auto",
        height: "auto",
        maxWidth: "95%",
        position: "relative",
        bottom: 10
    },
    titleLinks: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        color: SECONDARY
    },
    textLinks: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 10,
        color: SECONDARY,
        fontSize: 15
    },
    titleEvents: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        height: "1%",
        color: PRIMARY,
        fontSize: 20
    },
    titleRewards: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        color: SECONDARY
    },
    viewButtonContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 100,
    },
    redeemButtonContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
    },
    redeemButton: {
        width: "100%",
        height: 45,
        maxWidth: "100%",
        cursor: "pointer",
    },
    rewardContainer: {
        width: "90%",
        maxWidth: "95%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
    },
    rewardImage: {
        width: "auto",
        height: "auto",
        maxWidth: "100%",
    },
    rewardInfoContainer: {
        display: "flx",
        flexDirection: "column",
        alignItems: "flex-start",
        height: 20,
    },
    rewardText: {
        color: PRIMARY,
        fontSize: 14,
        lineHeight: 0.5,
        margin: 1
    },
    rewardTextBold: {
        color: PRIMARY,
        fontWeight: "bold",
        fontSize: 14,
        lineHeight: 0.5,
        marginRight: 4
    },
    eventContentContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "75%",
        width: "90%",
        padding:0,
    },
    eventImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        paddingBottom: 10,
    },
    imageContainer: {
        width: "auto",
        height: 130,
        marginBottom: 10,
    },
    eventInfoContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
    },
    eventTextContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: 95,
    },
    eventDate: {
        fontWeight: "bold",
        color: PRIMARY,
        fontSize: 16,
        marginTop: 0,
        marginBottom: 2
    },
    eventName: {
        fontSize: "0.9em",
        color: SECONDARY,
        marginTop: 0,
        marginBottom: 3,
        fontWeight: "bold"
    },
    eventSchool: {
        color: SECONDARY,
        fontSize: "0.8em",
        margin: 0
    },
    eventTime: {
        lineHeight: 0.5,
        color: SECONDARY,
        fontSize: 14
    },
    eventCredits: {
        color: SECONDARY,
        fontSize: 14,
        fontWeight: "bold",
        textTransform: "uppercase",
        margin: 0
    },
    gridTitle: {
        textTransform: "uppercase",
        fontWeight: "bold",
        color: "red"
    },
    creditsCircleContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    creditText: {
        marginTop: 5, 
        fontSize: 14,
        color: SECONDARY
    },
    dotRow: {
        display: "flex",
        justifyContent: "space-around"
    },
    swiperTextSecondary: {
        color: SECONDARY,
        margin: 0,
    },
    swiperTextBoldSecondary: {
        fontWeight: "bold",
        color: SECONDARY,
        margin: 0,
    },
    swiperTextPrimary: {
        color: PRIMARY,
        margin: 0,
    },
    swiperTextBoldPrimary: {
        fontWeight: "bold",
        color: PRIMARY,
        margin: 0,
    },
}