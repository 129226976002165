import { makeStyles } from "@mui/styles";
// import { theme } from '../../utils/theme';
// import { useTheme } from '@mui/material/styles';

export const useStyles = makeStyles(theme => ({
    calendarDetailContainer: {
      width: "95%",
      display: "flex",
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: "flex-start",
    },
    detailViewButton: {
        display: 'flex',
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
    },
    controlContainer: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        paddingTop: 30,
        paddingBottom: 30,
    },
    eventListContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    noEventContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        paddingTop: 40,
    },
    eventDescription: {
        paddingBottom: 10,
        paddingTop: 10,
    },
    listItemWrapper: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'center',
        padding: "10px 0",
        "&:hover": {
            backgroundColor: theme.palette.neutral.light,
            cursor: 'pointer'
        },
    },
    detailWrapper: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        
    },
    image: {
        maxWidth: 100,
        paddingRight: 20,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 75
          },
    },
    pointsWrapper: {
        display: 'inline-flex',
        paddingRight: 10,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
          },
    },
    circle: {
        paddingRight: 10,
        [theme.breakpoints.down('sm')]: {
            padding: 5
        },
    },
    pieCircle: {
        position: 'relative',
        marginRight: 10,
        width: 60,
        height: 60,
        [theme.breakpoints.down('sm')]: {
            margin: 5,
            width: 45,
            height: 45,
        },
    },
    pieText: {
        position: 'absolute',
        top: 0,
        left: 0,
        // transform: "translate(-50%, -50%)"
    },
    arrowHover: {
        "&:hover": {
            cursor: 'pointer',
            // opacity: "0.7",
            backgroundColor: theme.palette.neutral.light,
        }
    }
  }));

export const styles = {
    title: {
        fontFamily: 'Poppins',
        textTransform: 'uppercase',
        textAlign: 'center',
        maxWidth: "80vw",
        // paddingTop: "10px"
    },
    viewButton: {
        fontFamily: 'Poppins',
        textTransform: 'uppercase',
        textAlign: 'center',
        "&:hover": {
            cursor: 'pointer',
            opacity: "0.7"
        },
    },
    date: {
        fontFamily: 'Poppins',
        textTransform: 'uppercase',
        textAlign: 'left',
        fontWeight: 600,
    },
    body: {
        fontFamily: 'Poppins',
        textTransform: 'uppercase',
        textAlign: 'left',
    },
}