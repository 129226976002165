import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from '@mui/material/ListItemButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from "@mui/styles";
import { signOut } from "firebase/auth";
import { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../../context";
import { auth } from "../../../firebase";
import translate from '../../../i18n/translate';
import logo from '../../../res/logo.png';
import { theme } from '../../../utils/theme';
import { DividerLine } from '../../dividerLine';
import { NavCredits } from '../navCredits';
import { NavFooter } from '../navFooter';
//import { LoginReward } from "../../redeem-reward-points/loginReward.js";

const useStyles = makeStyles(theme => ({
  navContainer: {
    height: '100vh',
    [theme.breakpoints.down('xl')]: {
      height: '100%',
    },
    width: '20vw',
    display: 'flex',
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    overflow: 'scroll'
  },
  drawer: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: 25,
    height: '100%',
    width: '15vw',
    alignItems: 'center',
    [theme.breakpoints.down('xl')]: {
      width: '20vw',
    },
  },
  menuTitleContainer: {
    width: "100%",
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 12,
    marginBottom: 12,
  },
  menuButtonContainer: {
    width: "100%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 60,
    paddingBottom: 40,
  },
  navFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    width: 100,
  },
}));

const style = {
  root: {
    color: theme.palette.secondary.main,
    paddingLeft: 1,
    fontFamily: 'Poppins',
    textTransform: 'capitalize'
  }
}

export const DesktopSideNav = () => {
  const { navList, setStudents, parent, setParent,showRewards, setShowRewards } = useContext(Context);
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const showOnDesktop = useMediaQuery(theme.breakpoints.up('xl'));

  useEffect(() => {
   setShowRewards(true);
  }, [showRewards]);

  
const onSignout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        setStudents([]);
        setParent(null);
        setShowRewards(false);
        navigate("/");
      }).catch((error) => {
        // An error happened.
      });
  }

  const navigateTo = (link) => {
    setTimeout(() => {
      navigate(link);
    }, 250);
  }

  const checkActiveLink = (item) => {
    if (item.link === location.pathname) {
      return {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: 18,
        paddingLeft: 1,
        fontFamily: 'Poppins',
        textTransform: 'capitalize'
      }
    } else {
      return {
        color: theme.palette.secondary.main,
        paddingLeft: 1,
        fontFamily: 'Poppins',
        textTransform: 'capitalize'
      }
    }
  }

  return (
    <>
   
      <Paper className={classes.navContainer} elevation={4}>
        <div className={classes.drawer}>
          <Box
            sx={showOnDesktop ? { width: '15vw' } : { width: '20vw' }}
            role="presentation"
          >
            <div className={classes.menuTitleContainer}>
              <img style={{ width: '80%' }} src={logo} alt="fireSpot" />
            </div>
            <List>
              {navList.map((text) => (
                <ListItem key={text.name} onClick={() => navigateTo(text.link)} >
                  <ListItemButton>
                  <ListItemText disableTypography={true} primary={translate(text.name)} sx={checkActiveLink(text)} />
                  </ListItemButton>
                </ListItem>
              ))}
              <ListItem key={translate('logout')} onClick={onSignout}  >
              <ListItemButton>
                <ListItemText disableTypography={true} primary={translate('logout')} sx={style.root} />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
          <DividerLine />
        {/*Below is total credit logic*/}
              <NavCredits credits={parent?.totalCredits - parent?.creditsRedeemed} /> {/*parent?. will return undefined if parent is null or undefined, instead of causing a runtime error.*/}
              <DividerLine />
           
        
          <div className={classes.navFooter}>
            <NavFooter />
          </div>
        </div>
      </Paper>
    </>
  );
};
