import RewardsDesktop from '@components/rewards/layouts/RewardsDesktop';
import RewardsMobile from '@components/rewards/layouts/RewardsMobile';
import { Context } from '@context';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import { useContext } from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    // [theme.breakpoints.down('sm')]: {
    //   backgroundColor: theme.palette.primary.main,
    // },
  },
}));


export const Credits = () => {
  const classes = useStyles();
  const theme = useTheme();
  const showMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const showDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const { getData, parent } = useContext(Context);

  // console.log('parent', parent);

  // useEffect(() => {
  //   getData()
  // },[])
  
  return (
    <div className={classes.container}>
      {showMobile ? <RewardsMobile size="sm"/> : showDesktop ? <RewardsDesktop/> : <RewardsMobile size="md"/>}
    </div>
  );
};