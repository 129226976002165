import { PRIMARY, SECONDARY } from "@constraints";
import { Box, Button, Link, Modal, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  getAuth, sendPasswordResetEmail
} from "firebase/auth";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import translate from "../../i18n/translate";
import styles from './styles';

export const ForgotPasswordForm = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [alertModal, setAlertModal] = useState(false);

  const theme = useTheme();
  const showMobile = useMediaQuery(theme.breakpoints.down('md'));

  const onResetPassword = (e) => {
    e.preventDefault();
    sendPasswordResetEmail(auth, email)
    .then(() => {
      alert(`Password reset email sent to ${email}. If you cannot find it, please also check your spam folder.`);
      navigate("/");
    })
    .catch((error) => {
      console.log(error);
      // alert(`We were able to look up your contact information in the database, and it looks like your contact information needs to be updated. You can use the following link to change your contact information, including your email address:${'\n'}https://teams.lubbockisd.org/selfserve/EntryPointHomeAction.do?parent=true`);
      setAlertModal(true);
    });
  };

  const onClick = () => {
    setAlertModal(false);
  }

  const modalStyles = {
    box : {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: showMobile ? "80%" : "50%",
        bgcolor: 'background.paper',
        border: `3px solid ${SECONDARY}`,
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    headingText: {
      color: PRIMARY,
      fontWeight: "bold",
      fontSize: showMobile ? 16 : 20,
    },
    textBPrimary: {
      color: PRIMARY,
      fontWeight: "bold",
      fontSize: showMobile ? '0.8em' : "1em",
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    textBSecondary: {
      color: SECONDARY,
      fontWeight: "bold",
      fontSize: showMobile ? '0.8em' : "1em",
    },
    button: {
      borderRadius: 0,
      width: "40%", 
      height: 45,
      fontWeight: "bold"
    },
};

  return (
    <div style={styles.mainContainer}>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '30ch' },
        }}
        noValidate
        autoComplete="off"
        style={{display: "flex", flexDirection: "column", alignItems: "center", marginBottom: 20}}
      >
        <Typography variant="h6" gutterBottom component="div" color="secondary" style={{display: "flex", justifyContent: "center"}}>
            {translate('resetpassword')}
        </Typography>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
        <Typography variant="subtitle1" component="div" color="secondary" textAlign='center' style={{display: "flex", marginBottom: 20, justifyContent: "center"}}>
            {translate('resetdescription')}
        </Typography>
          <form style={{display: "flex", flexDirection: "column", justifyContent: "scenter"}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}> 
                <TextField
                    fullWidth 
                    id="outlined-basic" 
                    label={translate('emailaddress')} 
                    variant="outlined" 
                    color="secondary"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    style={{marginBottom: 20}}
                />
            </div>
            <Button style={{marginBottom: 20}} variant="contained" type="submit" onClick={(e) => onResetPassword(e)}>{translate('submit')}</Button>
          </form>
          <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
            <Link href="/" underline='none' color='secondary'>{translate('returntologin')}</Link>
          </div>
        </div>
      </Box>
      <Modal
        open={alertModal}
        onClose={() => setAlertModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyles.box}>
          <p style={modalStyles.headingText}><strong style={modalStyles.headingText}>Email Address Not Found</strong></p>
          <p style={{textAlign: "center", marginBottom: 20,}}>
              <strong style={modalStyles.textBSecondary}>
              {`We were able to look up your contact information in the database, and it looks like your contact information needs to be updated. You can use the following link to change your contact information, including your email address:`}
              </strong>
          </p>
          <p style={{textAlign: "center", marginBottom: 40,}}>
            <Link style={modalStyles.textBPrimary} href="https://teams.lubbockisd.org/selfserve/EntryPointHomeAction.do?parent=true" target={"_blank"}>https://teams.lubbockisd.org/selfserve/EntryPointHomeAction.do?parent=true</Link>
          </p>
          <Button variant="contained" style={modalStyles.button} onClick={onClick}>Okay</Button>
        </Box>
      </Modal>
    </div>
  );
};