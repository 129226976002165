import { db } from "@database";
import { filterPastEvents, getAllEventsWithStudents, orderEventsByDate } from "@functions";
import { collection, onSnapshot } from "firebase/firestore";
import { useState } from "react";


export const subscribeEvents = (arrayEvents, setTrue, allSetTrue, studentsArray, setAllEvents, setAllEventsFiltered, setFutureEvents, setFutureEventsFiltered) => {

  try {
    const eventsRef = collection(db, `events`)
    onSnapshot(eventsRef, (snapshot) => {
      if (snapshot.docs.length > 0) {
        const eventsArray = snapshot.docs.map((event) => ({
          ...event.data(),
          dateString: event.data().date.toDate().toDateString(),
          id: event.id
        }));
        // setAllFutureEvents(orderEventsByDate(getAllEventsWithStudents(filterPastEvents(eventsArray), studentsArray)))
        // const allEvents = orderEventsByDate(getAllEventsWithStudents(eventsArray, studentsArray));
        const allEvents = orderEventsByDate(getAllEventsWithStudents(eventsArray, studentsArray));
         //console.log('allEvents', allEvents);
        // const futureEvents = orderEventsByDate(getAllEventsWithStudents(filterPastEvents(eventsArray), studentsArray));
        // console.log("ALL EVENTS", allEvents);
        const futureEvents = orderEventsByDate(filterPastEvents(allEvents));
        // console.log("FURURE EVENTS", futureEvents);
        if(setTrue){
        setAllEvents(allEvents);
        allSetTrue(false)
        arrayEvents = allEvents
        //console.log("setTrue",setTrue)
        //console.log("arrayEvents",arrayEvents)

        }
        setAllEventsFiltered(allEvents);
        setFutureEvents(futureEvents);
        setFutureEventsFiltered(futureEvents);
        // setEvents(orderEventsByDate(getAllEventsWithStudents(filterPastEvents(eventsArray), studentsArray)));
        // setFilteredEvents(orderEventsByDate(getAllEventsWithStudents(filterPastEvents(eventsArray), studentsArray)));
      }
    })
  } catch (error) {
    console.log("ERROR SUBSCRIBING TO EVENTS COLLECTION", error);
  }
};