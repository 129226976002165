import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForward from "@mui/icons-material/ArrowForward";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Skeleton, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from "@mui/styles";
import { useContext, useEffect, useState } from "react";
import Calendar from "react-calendar";

import { CalendarDayDetail } from "./CalendarDayDetail";
import { MarkedDate } from './MarkedDate';

import { Context } from '@context';
import translate from '@translate';

import "./eventCalendar.css";

const useStyles = makeStyles(theme => ({
  calendarContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: "center",
  },
  spinnerContainer: {
    width: "100%",
    height: "65vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: 'center',
  },
  detailContainer: {
    position: 'absolute',
    left: 5,
    top: 0,
    // m:1,
    width: "99%",
    height: "85vh",
    transform: "translateX(-50%)",
    display: "flex",
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      left: 0,
      height: "100%",
    },
  }
}));

const styles = {
    reset: {
        mt: 3,
        fontFamily: 'Poppins',
        textTransform: 'uppercase',
        fontWeight: 800,
        textDecoration: 'underline',
        "&:hover": {
            cursor: 'pointer',
            opacity: "0.8"
        }
    },
    title: {
        fontFamily: 'Poppins',
        textTransform: 'uppercase',
        textAlign: 'center',
        maxWidth: "60vw",
        paddingTop: "10px",
        paddingBottom: "10px"
    },
}

export const EventCalendar = () => {
  const [checked, setChecked] = useState(false);
  const [value, onChange] = useState(new Date());
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null)
  const { locale, allEventsFiltered, students, mark } = useContext(Context)

  const classes = useStyles();
  const theme = useTheme();

  const showMobile = useMediaQuery(theme.breakpoints.down('md'));

  const formatMarkDate = (date) => {
      //console.log(date);
      return `${(date.getMonth() + 1).toString()}-${date
        .getDate()
        .toString()}-${date.getFullYear()}`;
    };

  const setEventsForDate = (date, action) => {
    //let dateEvents = events.filter((event) => Date.parse(new Date(event.date.seconds * 1000)) === Date.parse(new Date(date)))
    let dateEvents = allEventsFiltered.filter((event) => Date.parse(formatDate(event.dateString)) === Date.parse(formatDate(date)));
    setSelectedDate({
      date: date,
      events: dateEvents
    })
    if(action === "open") {
      setChecked(!checked)
    }
  }
  const formatDate = (date) => {
    const options = { year: "numeric", month: "numeric", day: "numeric"};
    const dateToConvert = new Date(date);
    return dateToConvert.toLocaleDateString("en-US", options);
  };
  const calendarDetail = (
    <Paper className={classes.detailContainer} elevation={0}>
        <CalendarDayDetail
          checked={checked}
          setChecked={setChecked}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setEventsForDate={setEventsForDate}
        />
    </Paper>
  )

  const dayAbbv = {
    "en-us": ["S", "M", "T", "W", "T", "F", "S"],
    "es-es": ["D", "L", "M", "M", "J", "V", "S"]
  }

  useEffect(() => {
    if ((allEventsFiltered.length && students.length && mark.length)) {
      setLoadingCalendar(false)
    }
  }, [allEventsFiltered, students, mark])
  // useEffect(() => {
  //   getData();
    
  // },[])

  return (
    <div className={classes.calendarContainer}>
        {loadingCalendar ? 
        <div className={classes.spinnerContainer}>
          <Skeleton variant="text" width={"90%"} height={150} animation="wave" sx={{mb: 2}}/>
          <Skeleton variant="rectangular" width={"90%"} height={"100%"} animation="wave" />
        </div>
        :
        <>
          {/* <Typography variant={showMobile ? "body2" : "h6"} color="secondary" sx={styles.title}>{translate("selectDate")}</Typography> */}
          <Typography variant={"body1"} color="secondary" sx={styles.title}>{translate("calendarDescription")}</Typography>
          <Calendar
              onChange={onChange}
              value={value}
              nextLabel={showMobile ? <ArrowForwardIosIcon color="secondary" fontSize="small" /> : <ArrowForward color="secondary" />}
              next2Label={null}
              prevLabel={showMobile ? <ArrowBackIosNewIcon color="secondary" fontSize="small" /> : <ArrowBack color="secondary" />}
              prev2Label={null}
              locale={locale}
              showDoubleView={false}
              formatShortWeekday={(locale, date) =>
              dayAbbv[locale][date.getDay()]
              }
              calendarType="iso8601"
              minDetail="year"
              tileContent={({ date, view }) => {
                if (mark.length && mark.find((x) => x === formatMarkDate(date))) {
                  return <MarkedDate date={date} />;
                }
              }}
              onClickDay={(value) => {
                // console.log(formatMarkDate(new Date(value)));
                setEventsForDate(value, "open");
              }}
          />
        </>
        }
        <Slide direction="down" in={checked} mountOnEnter unmountOnExit onExited={() => setSelectedDate(null)}>
          {calendarDetail}
        </Slide>
    </div>
  );
};
