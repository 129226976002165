import React from 'react';
import { Typography } from '@mui/material';
import { PRIMARY, SECONDARY } from '@constraints';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Campus = ({item}) => {

  const theme = useTheme();
  const showMobile = useMediaQuery(theme.breakpoints.down('md'));

  return(
    <div style={{ display: 'flex', alignSelf: 'center', flexDirection: 'column', width: showMobile ? '100%' : 300 , justifyContent: 'center', alignItems: 'center', borderBottom: "1px solid lightgray", margin: 10}}>
      <Typography variant={"body2"} color={PRIMARY}>{item.name} ({item.type})</Typography>
      <Typography variant={"body1"}>{item.address}</Typography>
      <Typography variant={"body2"} color={SECONDARY}>{item.phone}</Typography>
      
    </div>
  )

}

export default Campus;