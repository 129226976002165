import { db } from "@database";
import { collection, getDocs } from "firebase/firestore";

export const getCampusesData = async (setCampuses) => {
  try {
    //console.log('called');
    const campusesQuery = collection(db, `campuses`);
    const campusesSnapshot = await getDocs(campusesQuery);
    //console.log('campusesArray', campusesSnapshot.docs);
    let campusesArray = [];
    campusesSnapshot.docs.forEach((doc) => {
      const campus = {
        campus: doc.id,
        name: doc.data().name,
        address: doc.data().address,
        phone: doc.data().phone,
        type: doc.data().type
      }
      campusesArray.push(campus)
    });
    
    setCampuses(campusesArray);
  } catch (error) {
    console.log("ERROR GETTING STUDENT DATA", error)
  }
};