import { db } from "@database";
import { doc, getDoc } from 'firebase/firestore';

export const checkIfRedeemed = async (parent, reward) => {
  try{
      const docRef = doc(db, 'parents', parent.parentId);
      const docRef2 = doc(docRef, 'redemptions', reward.id);
      const docSnap = await getDoc(docRef2);
      if (docSnap.exists()) {
          return true;
      } else {
          return false;
      };
  } catch(e){
      console.log(e);
      return false;
  }
}