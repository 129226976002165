import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import './table.css';
import {useIntl} from 'react-intl';


const DataTable = ({ data }) => {
  const translateTostring = useIntl();
  const columns = [
  
    {
      field: 'name',
      headerName: translateTostring.formatMessage({id: 'Name'}),
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      
    },
    {
      field: 'cost',
      headerName: translateTostring.formatMessage({id: 'Credits'}),
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: true,
    },
    {
      field: 'date',
      headerName: translateTostring.formatMessage({id: 'Date'}),
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: true,
    },
  ];
//console.log(translate('Name').props)
  return (
    <>
      <div
        style={{
          height: 600,
          width: '100%',
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          rowHeight={85}
          sx={{ border: 'none', color: '#3B3C86' }}
          disableColumnMenu={true}
        />
      </div>
    </>
  );
};

export default DataTable;
