import { db } from "@database";
import { doc, getDoc, setDoc } from "firebase/firestore";

export const updateParentCredits = async (parentId, event, eventStudents) => {
  const parentDocRef = doc(db, "parents", parentId);
  const creditRef = doc(parentDocRef, "credits-history", event.id);

  const credit = {
    date: event.dateString,
    credits: parseInt(event.reward),
    name: event.name,
    students: eventStudents,
  };

  try {
    const parent = await getDoc(parentDocRef);
    const totalCredits = (parent.exists() && parent.data().totalCredits) ?? 0;
    const creditsNow = parseInt(totalCredits) + parseInt(event.reward);
    await setDoc(parentDocRef, {totalCredits: creditsNow}, {merge: true});
    await setDoc(creditRef, credit);
  } catch (error) {
    console.log("error updating parent credits")
  }
};