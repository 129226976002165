import { Context } from '@context';
import { getRewardStatus } from "@functions";
import { useWindowDimensions } from '@hooks';
import LockIcon from '@mui/icons-material/Lock';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useContext } from 'react';
import styles from "./styles";

export default function RewardCarouselCard({reward}) {
    const { parent } = useContext(Context);
    const { height, width } = useWindowDimensions();

    const theme = useTheme();
    const showPhone = useMediaQuery(theme.breakpoints.down('sm'));

    if(reward && parent) {
        return (
            <div style={styles.cardContainer}>
                <div style={
                    //Checking whether the user has enough points to unlock the reward,
                    //Also checking whether a phone or tablet is being used
                    getRewardStatus(reward, parent.totalCredits) === "unlocked"  && showPhone ? 
                    {height: height / 12, marginBottom: 5, padding: 15, border: "1px solid lightgray", display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden"} 
                    : getRewardStatus(reward, parent.totalCredits) !== "unlocked"  && showPhone ?
                    {height: height / 12.5, marginBottom: 5, padding: 15, border: "3px solid lightgray", display: "flex", justifyContent: "center", alignItems: "center"}
                    : getRewardStatus(reward, parent.totalCredits) === "unlocked"  && !showPhone ?
                    {height: height / 7, width: width / 3, marginBottom: 5, padding: 15, border: "1px solid lightgray", display: "flex", justifyContent: "center", alignItems: "center"}
                    : 
                    {height: height / 7, width: width / 3, marginBottom: 5, padding: 15, border: "3px solid lightgray", display: "flex", justifyContent: "center", alignItems: "center"}
                } 
                >
                    {getRewardStatus(reward, parent.totalCredits) === "unlocked" ?
                    <img style={styles.rewardImage} src={reward.image} alt="fireSpot"/>
                    :
                    <LockIcon sx={{ color: "lightgray", width: "auto", height: "auto", maxWidth: "40%", }}/>
                    }
                </div>
                <p style={styles.swiperTextBoldSecondary}>{getRewardStatus(reward, parent.totalCredits).toUpperCase()}</p>
                <p style={styles.swiperTextSecondary}>{reward.description}</p>
                <p style={styles.swiperTextBoldSecondary}>{`${reward.cost}PTS`}</p>
            </div>
        )
    } else {
        return (
            <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
        )
    }
}
