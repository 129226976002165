import { db } from "@database";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";

export const saveNote = async (parentId, type, noteId, note) => {
  try{
    const parentRef = doc(db, 'parents', parentId);
    if (type === "edit") {
      const noteRef = doc(parentRef, 'notes', noteId);
      await setDoc(noteRef, note, { merge: true});
    } else {
      const notesCollectionRef = collection(parentRef, 'notes');
      await addDoc(notesCollectionRef, note);
    }
  } catch(e) {
    console.log(e);
  }
}