import { getAuth } from "firebase/auth";
import { db } from "@database";
import { collection, query, where, getDocs, limit } from "firebase/firestore";

// Function to get the current user
const getCurrentUser = () => {
  const auth = getAuth();
  if (!auth.currentUser) throw new Error("No current user found");
  return auth.currentUser;
};

// Fetches the parentId from the current user's claims
const fetchParentIdFromClaims = async (currentUser) => {
  const idTokenResult = await currentUser.getIdTokenResult();
  //console.log("ID TOKEN RESULT", idTokenResult);
  return idTokenResult.claims.parentId;
};

// Function to query the database for parentId
const queryParentIdFromDB = async (email) => {
  const parentsCollectionRef = collection(db, "parents");
  const q = query(parentsCollectionRef, where("emails", "array-contains", email), limit(1));
  const snapshot = await getDocs(q);

  if (!snapshot.empty) {
    const parentDoc = snapshot.docs[0];
    return parentDoc.data().parentId;
  }
  return null;
};

// Main function to get parentId
export const getParentId = async () => {
  try {
    const currentUser = getCurrentUser();
    const parentIdFromClaims = await fetchParentIdFromClaims(currentUser);

    if (parentIdFromClaims) return parentIdFromClaims;

    const updatedParentId = await queryParentIdFromDB(currentUser.email);

    if (!updatedParentId) throw new Error("parentId still not found after checking the db.");

    return updatedParentId;
  } catch (error) {
    console.error("ERROR GETTING PARENT DATA", error);
    throw error; // Allows for external error handling
  }
};
