export const checkIfEventIsPast = (event) => {
  let eventDate = new Date(event.dateString);
  let todaysDate = new Date();
  // console.log("EVENTS DATE STRING", eventDate)
  // call setHours to take the time out of the comparison
  if(eventDate.setHours(0,0,0,0) < todaysDate.setHours(0,0,0,0)) {
    return true
  } else {
    // console.log("EVENTS FUTURE")
      return false
  } 
};