import Ticker from "@components/ticker";
import { Context } from '@context';
import { db } from "@database";
import { TabContext, TabPanel } from '@mui/lab';
import {
  Box, Stack,
  TextField
} from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
//import translate from '@translate';
import { addDoc, collection, doc } from "firebase/firestore";
import { useContext, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import DataTable from './data-table';
import './form.css';
import { useStyles } from './style';
import {useLocation} from 'react-router-dom';
import translate, { useTranslate } from "../../i18n/translate";
import { DividerLine, VerticalDividerLine } from '@components/dividerLine/';

const CreditHistoryView = () => {
    const descriptionRequired = useTranslate("descriptionRequired")
    const eventName = useTranslate("eventName")
    const eventRequestCredit = useTranslate("eventRequestCredit")


    const location = useLocation();
    const { parent, redemptions, creditHistory, language } = useContext(Context);
    const classes = useStyles();
    const [value, setValue] = useState('0');
    const [open, setOpen] =  useState(location.state.isOpen);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const {
      control,
      register,
      formState: { errors },
      handleSubmit,
      reset
    } = useForm();
    const onRequestCredit = () => {
        setOpen(true);
    }
    const dateSelect =language ==="en-us" ? "Select Date": "Seleccione fecha"
    const onSubmit = async(data) => {
      
        try {
          // console.log('data', data);
            data.date = data.date.toDateString();
            const updateRef = doc(db, 'parents', parent.parentId);
            const colRef = collection(updateRef, 'request-history');
            await addDoc(colRef, data);
          setOpen(false);
          reset();
        } catch (e) {
          console.log('error writing to db', e);
        }
      };
    
    return(
      <>
        {parent &&
          <>
        <div className={classes.creditsContainer}>
        <Ticker 
            count={parent?.totalCredits - parent?.creditsRedeemed}
            text={translate("total available credits")}
            countSize={80}
            textSize={16}
        />
        <VerticalDividerLine />
        <Ticker 
            count={parent?.creditsRedeemed}
            text={translate("totalCreditsRedeemed")}
            countSize={80}
            textSize={16}
        />
        </div>
        <DividerLine />
        <div className={classes.buttonContainer}>
            <Button variant="outlined" className={classes.button} onClick={onRequestCredit}>{translate("eventRequestCredit")}</Button>
        </div>
        <TabContext value={value} >
            <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
                className={classes.tabsContainer}
            >
                <Tab label={translate("Available Credits")} value="0" />
                <Tab label={translate("Redeemed Credits")} value="1" />
            </Tabs>
            {redemptions && <TabPanel value="1"><DataTable data={redemptions.map(item =>  ({id: item.id, name: item.name, cost: item.cost, date: item.date.toDate().toDateString()}))}/></TabPanel>}
            {creditHistory && <TabPanel value="0"><DataTable data={creditHistory.map(item =>  ({id: item.id, name: item.name, cost: item.credits, date: item.date}))}/></TabPanel>}
        </TabContext>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack className={classes.formContainer}>
              <div className={classes.innerView}>
              <div>{translate('eventName')}</div>
              <TextField
                
                sx={{
                  width: '100%',
                  height: '40px',
                  //border: '1px solid #3B3C86',
                  backgroundColor: '#fff',
                  borderRadius: 1,
                  marginTop: 1,
                  marginBottom: 2
                }}
                size='small'
                id='outlined-uncontrolled'
                {...register('name', { required: true })}
                placeholder={errors.name?.type === 'required' ? eventName : ""}
              />
              <div>{translate('eventDescription')}</div>
              <TextField 
                multiline
                rows={6}
                placeholder={
                  errors.description?.type === 'required' ?
                  descriptionRequired : ""
              }
                {...register('description', { required: true })}
              />
              <div>
                  <div>{translate('eventDate')}</div>
                  <Box
                    component='div'
                    sx={{
                      height: '40px',
                      width: '100%',
                      border: '1px solid grey',
                      backgroundColor: '#fff',
                      borderRadius: 1,
                      marginTop: 1,
                      marginBottom: 2
                    }}
                  >
                    <Controller
                      control={control}
                      name='date'
                      render={({ field }) => (
                        <DatePicker
                          sx={{
                            height: '40px',
                            border: '1px solid grey',
                            backgroundColor: '#fff',
                            borderRadius: 1,
                            marginTop: 1,
                          }}
                          
                          placeholderText={dateSelect
                          }
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                        />
                      )}
                    />{' '}
                  </Box>
                </div>
              <Button
                variant='outlined'
                color='success'
                type='submit'
                sx={{
                  
                  height: 40,
                  border: '1px solid #3B3C86 ',
                  opacity: 1,
                  fontSize: '10px',
                  
                  
                  borderRadius: 0,
                }}
              >
                {translate("Submit Request")}
              </Button>
              </div>
            </Stack>
          </form>
      </Modal>
     </>
     }
     </>
    )
}

export default CreditHistoryView;