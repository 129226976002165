import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from "@mui/styles";
import React from 'react';
import { DividerLine, VerticalDividerLine } from '../../dividerLine';


const useStyles = makeStyles(theme => ({
    footerContainer: {
        width: '100%',
        minWidth: 180,
        display: 'inline-flex',
        justifyContent: 'center',
        height: 62,
        paddingBottom: 15,
        paddingTop: 15,
        [theme.breakpoints.down('xl')]: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          },
    },
    footerAbv: {
        display: 'inline-flex',
        // justifyContent: 'center',
        // alignItems: 'flex-start'
    },
    abvContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: 10,
        [theme.breakpoints.down('xl')]: {
            padding: 15,
          },
    },
    addressContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
        [theme.breakpoints.down('xl')]: {
            padding: 10,
          },
    },
    phoneLink: {
        textDecoration: 'none',
        '&hover': {
            cursor: 'pointer',
        },
    },
  }))

export const NavFooter = () => {
    const classes = useStyles();
    const theme = useTheme();

    const showOnDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <div className={classes.footerContainer}>
            {!showOnDesktop && <DividerLine />}
            <div className={classes.abvContainer}>
                <div className={classes.footerAbv}>
                   
                    <Typography variant="h6"  sx={{  textAlign: 'center', color: theme.palette.secondary.main, fontFamily: 'Poppins', fontWeight: 800, lineHeight: 0}}>
                        Lubbock
                    </Typography>
                    <Typography variant="h6" sx={{ textAlign: 'center', color: theme.palette.primary.main, fontFamily: 'Poppins', fontWeight: 800, lineHeight: 0}}>
                        ISD
                    </Typography>
                </div>
            </div>
            {  
               showOnDesktop && <VerticalDividerLine />
            }
            <div className={classes.addressContainer}>
                <Typography variant={showOnDesktop ? "body2" : "body1"} align={showOnDesktop ? 'left' : 'center'}  sx={{ color: theme.palette.secondary.main, fontFamily: 'Poppins', fontWeight: 400, lineHeight: 1.25}}>
                    1628 19th Street
                </Typography>
                <Typography variant={showOnDesktop ? "body2" : "body1"} align={showOnDesktop ? 'left' : 'center'} sx={{ color: theme.palette.secondary.main, fontFamily: 'Poppins', fontWeight: 400, lineHeight: 1.25 }}>
                    Lubbock, TX 79401
                </Typography>
                <a href="tel:+18062190000" style={{textDecoration: 'none'}}>
                    <Typography variant={showOnDesktop ? "body2" : "body1"} align={showOnDesktop ? 'left' : 'center'} sx={{ color: theme.palette.primary.main, fontFamily: 'Poppins', fontWeight: 400, pt:1.25 }}>
                        806-219-0000
                    </Typography>
                </a>
            </div>
            {!showOnDesktop && <DividerLine />}
        </div>
    );
};
