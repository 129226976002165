import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { BreakpointProvider } from 'react-socks';
import "./App.css";
import { AuthProvider } from "./Auth";
import { ContextProvider } from "./context";
import { Router } from "./Router";


import { theme } from "./utils/theme";

function App() {
  return (
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <ContextProvider>
            <BreakpointProvider>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </BreakpointProvider>
          </ContextProvider>
        </AuthProvider>
      </ThemeProvider>
  );
}

export default App;