import { PRIMARY, SECONDARY } from "@constraints";
import { Context } from "@context";
import { db } from "@database";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import translate from "@translate";
import { getAuth } from "firebase/auth";
import { doc, getDoc, setDoc, Timestamp, updateDoc, arrayUnion } from 'firebase/firestore';
import { useContext } from 'react';
export default function RedeemModal({open, setOpen, reward, size, checkIfRedeemed}) {
  const { parent, getParentAndStudents } = useContext(Context);
  const styles = {
      box : {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: size === "lg" ? "30%" : "75%",
          bgcolor: 'background.paper',
          border: `3px solid ${SECONDARY}`,
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
      },
      headingText: {
        color: PRIMARY,
        fontWeight: "bold",
        fontSize: 20,
      },
      image: {
        width: "70%",
        height: "auto",
        objectFit: "cover",
      },
      textPrimary: {
        color: PRIMARY,
        fontSize: "1em",
        marginTop: 0
      },
      textBPrimary: {
        color: PRIMARY,
        fontWeight: "bold",
        fontSize: "1em",
      },
      textSecondary: {
        color: SECONDARY,
        fontSize: "1em",
        marginBottom: 0,
      },
      textBSecondary: {
        color: SECONDARY,
        fontWeight: "bold",
        fontSize: "1em",
      },
      button: {
        borderRadius: 0,
        width: "100%", 
        height: 45,
        fontWeight: "bold"
      },
  };

  const onClick = async () => {
    try {
      const docRef = doc(db, "parents", parent.parentId);
      const docRef2 = doc(docRef, "redemptions", reward.id);
      const docSnap = await getDoc(docRef2);
  
      const redemption = {
        name: reward.name,
        cost: reward.cost,
        description: reward.description,
        school: reward.school,
        date: Timestamp.now(),
        parentName: `${parent.firstName} ${parent.lastName}`,
        email: getAuth().currentUser.email,
        id:Timestamp.now()
      };
  
      if (!docSnap.exists()) {
        // If the document does not exist, create a new one
        await setDoc(docRef2, redemption);
      } else {
        // If the document exists, update it
        await updateDoc(docRef2, {
          redemption: arrayUnion(redemption),
        });
      }
  
      // Update the reward quantity
      const rewardRef = doc(db, "rewards", reward.id);
      const quantityLeft = reward.quantityLeft ?? reward.quantity;
      await setDoc(rewardRef, { quantityLeft: (quantityLeft - 1).toString() }, { merge: true });
  
      // Update the credits redeemed
      const creditDoc = doc(db, "parents", parent.parentId);
      const creditSnap = await getDoc(creditDoc);
      if (creditSnap.exists()) {
        const creditsRedeemed = creditSnap.data().creditsRedeemed ?? 0;
        await setDoc(creditDoc, { creditsRedeemed: parseInt(creditsRedeemed) + parseInt(reward.cost) }, { merge: true });
      }
  
    } catch (e) {
      console.log(e);
    }
  
    // Perform additional actions
    checkIfRedeemed(parent, reward);
    setOpen(false);
  }
  
  return (
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.box}>
          <p style={styles.headingText}>{`${reward.cost} `}<strong style={styles.headingText}>{translate("credits")}</strong></p>
          <img src={reward.image} style={styles.image} alt="fireSpot"/>
          <p style={{textAlign: "center", marginBottom: 4,}}>
              <strong style={styles.textBSecondary}>{translate("aboutToUse")}</strong>
              <strong style={styles.textBPrimary}>{` ${reward.cost} `}</strong>
              <strong style={styles.textBPrimary}>{translate("credits")}</strong>
              <strong>{" "}</strong>
              <strong style={styles.textBSecondary}>{translate("forThisItem")}</strong>
          </p>
          <p style={styles.textPrimary}>{reward.description}</p>
          <Button variant="contained" style={styles.button} onClick={onClick}>{translate("redeem now")}</Button>
        </Box>
      </Modal>
  );
}