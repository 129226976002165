import { Context } from '@context';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { makeStyles } from "@mui/styles";
import { Timestamp } from "firebase/firestore";
import { useContext, useState, useEffect } from 'react';
import Note from '../../components/note';
import translate from '../../i18n/translate';
import { saveNote } from '../../services/firebase';


const useStyles = makeStyles( theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 0,
    },
  },
}));

export const Notes = () => {
  const classes = useStyles();
  const { parent, notes, setNotes } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editNoteId, setEditNoteId] = useState(null);
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');


    
  //   if(data.length <= 0)
  //   fetchNotes();
  //   // return () => setData([]);
  // },[])

  // const fetchNotes = async() => {
  //   try{
  //     const docRef = doc(db, 'notes', parent.parentId);
  //     const colRef = collection(docRef, 'notes');
  //     const colSnap = await getDocs(colRef);
  //     const tempData = []
  //     colSnap.docs.forEach((doc, idx) => { 
  //       tempData.push(doc.data());
  //       tempData[idx].id = doc.id;
  //       tempData[idx].dateString = doc.data().date.toDate().toDateString();
  //     })
  //     setData(tempData);
  //   } catch(e) {
  //     console.log(e);
  //   }

  // }

  const onAddNote = () => {
    setOpen(true);
  }


  const onSave = async () => {
    try{
      if(isEdit) {
        setOpen(false);
        const note = {
          subject: subject,
          description: description,
        };
        await saveNote(parent.parentId, "edit", editNoteId, note);
        setIsEdit(false);
        setEditNoteId(null);
      } else {
        setOpen(false);
        const note = {
          subject: subject,
          description: description,
          date: Timestamp.now(),
        };
        await saveNote(parent.parentId, "new", null, note);
        setIsEdit(false);
        setEditNoteId(null);
      }
      setSubject('');
      setDescription('');
    } catch(e) {
      console.log(e);
    }
  }

  const onEdit = (item) => {
    setIsEdit(true);
    setEditNoteId(item.id);
    setSubject(item.subject);
    setDescription(item.description);
    setOpen(true);
  }

  const onCancel = () => {
    setOpen(false);
    setIsEdit(false);
    setEditNoteId(null);
    setSubject('');
    setDescription('');
  }
   
  const handleRemoveNote = (noteId) => {
    setNotes(notes.filter(note => note.id !== noteId) );
  };

  return (
    <>
    <div className={classes.buttonContainer}>
        <Button variant="outlined" className={classes.button} onClick={onAddNote}>{translate("addANote")}</Button>
      </div>
    <div className={classes.container}>
      
      {notes.length > 0 && notes.map(note => <Note note={note} onEdit={onEdit} onRemove={handleRemoveNote} key={note.id}/>)}
    </div>
    <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{translate("Add a Note")}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="subject"
            label={translate("Subject")}
            type="text"
            fullWidth
            variant="standard"
            value={subject}
            onChange={(event) => setSubject(event.target.value)}
          />
          <TextField
            margin="dense"
            id="description"
            label={translate("description")}
            type="text"
            fullWidth
            multiline={true}
            variant="standard"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{translate("Cancel")}</Button>
          <Button onClick={onSave}>{translate("save")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}