import { Context } from '@context';
import { useContext } from 'react';
import DashboardCard from '../subcomponents/dashboard-card';
import StudentList from '../subcomponents/students-list';
import styles from './styles';

export default function DashboardDesktop() {
    const cardTypes = [/*"AppLinks"*/, "Events", "Rewards"]
    const { students } = useContext(Context);

    return (
        <div style={{paddingLeft: 20}}>
            <div style={styles.cardsContainer}>
                {cardTypes.map((type, idx) => {
                    return(
                        <DashboardCard key={idx} type={type} cardSize={"desktop"}/>
                    )
                })}
            </div>
            <div style={{marginTop: 40}}>
                <StudentList students={students}/>
            </div>
        </div>
    )
}
