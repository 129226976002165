import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme => ({
    creditsContainer: {
        
        display: "flex",
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: "center",
        height: 100,
        marginBottom: 20,
    },
    tabsContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'left',
        padding: 30,
        marginLeft: 20,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 20,
    },
    button: {
        borderRadius: 0,
        width: '50%', 
        height: 45,
        fontWeight: "bold",
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            width:'95%'
        },
    },
    formContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 50,
    },
    innerView: {
        display: 'flex',
        flexDirection: 'column',
        padding: 30,
        backgroundColor: '#fff',
    }
  }));
