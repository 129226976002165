import { checkIfEventIsPast } from ".";

export const filterPastEvents = (events) => {
  const notPastEvents = []
  events.forEach((event) => {
      if(!checkIfEventIsPast(event)) {
          notPastEvents.push(event)
      }
  })

  return notPastEvents
};