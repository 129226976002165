export const orderEventsByDate = (events) => {
  // console.log("FUTURE EVENTS IN ORDER", events);
  const eventsToSort = events;
  const orderedDates = eventsToSort.sort(function(a, b) {
      var keyA = new Date(a.dateString),
        keyB = new Date(b.dateString);
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
  });
  // const orderedDates = eventsToSort.sort(function(a,b){return new Date(a.dateString).toISOString() > new Date(b.dateString).toISOString() ? 1 : new Date(a.dateString).toISOString() < new Date(b.dateString).toISOString() ? -1 : 0 });
  // console.log("EVENT DATE", orderedDates)
  return orderedDates;
};