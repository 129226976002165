// Import Swiper React components
import TouchableOpacity from "@components/buttons/touchable";
import { Button } from '@mui/material';
import translate from '@translate';
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import EventCarouselCard from "./EventCarouselCard";
import RewardCarouselCard from "./RewardCarouselCard";
import styles from "./styles";
import { Thumbs } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';

export default function Carousel({ data, type}) {
    const navigate = useNavigate();

    return (
        <div style={styles.swiperContainer}>
            <div style={styles.swiperHeader}>
              <p style={type === "events" ? styles.swiperTitlePrimary : styles.swiperTitleSecondary}>{type.toUpperCase()}</p>
              {type === "events" && <Button variant="text" style={styles.swiperButton} onClick={() => navigate("/home/calendar")}>{translate("VIEW CALENDAR")}</Button>}
              {type === "rewards" && <Button variant="text" style={styles.swiperButtonSecondary} onClick={() => navigate("/home/credits")}>{translate("VIEW CREDITS")}</Button>}
            </div>
            <Swiper
              spaceBetween={15}
              slidesPerView={2.4}
            //  onSlideChange={() => console.log('slide change')}
              //onSwiper={(swiper) => console.log(swiper)}
              style={{height: '30vh'}}
            >
             {data.map((item, index) => {
                return (
                <SwiperSlide key={index} style={styles.slideContainer}>
                  {/* <LazyLoad height={"100%"} offsetHorizontal={50}> */}
                    <TouchableOpacity>
                      <div onClick={() => navigate(`/home/${type === "events" ? "event-list" : "credits"}`)}>
                        {type === "events" ? <EventCarouselCard event={item} isSoonestEvent={index === 0}/> : <RewardCarouselCard reward={item}/>}
                      </div>
                    </TouchableOpacity>
                  {/* </LazyLoad> */}
                </SwiperSlide>
                )
            })}
            </Swiper>
        </div>
    )
}