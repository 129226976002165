import StudentCircle from "@components/student-circle";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useContext, useEffect, useState } from "react";

import { Context } from '@context';

import "./eventCalendar.css";

export const MarkedDate = ({date}) => {
    const [schools, setSchools] = useState(null)
    const { allEventsFiltered, students } = useContext(Context);

    const theme = useTheme();

    const showMobile = useMediaQuery(theme.breakpoints.down('md'));

    const checkForEvents = () => {
        //let dateEvents = events.filter((event) => Date.parse(new Date(event.date.seconds * 1000)) === Date.parse(new Date(date)))
        let dateEvent = allEventsFiltered.filter((event) =>  Date.parse(formatDate(event.dateString)) === Date.parse(formatDate(date)))
        setSchools(dateEvent)
    };

    const formatDate = (date) => {
        const options = { year: "numeric", month: "numeric", day: "numeric"};
        const dateToConvert = new Date(date);
        return dateToConvert.toLocaleDateString("en-US", options);
      };

    useEffect(() => {
        checkForEvents()
    },[allEventsFiltered, students])

    return (
      <div className="calendarMarkedDateContainer">
          <div className="calendarMarkedDate">
                {schools && students.length && students.map((student) => {
                        if (schools.some((school) => school.school === student.studentSchool || school.school === 'Select All')) {
                            {/* console.log(`${Date.parse(date)}${student.studentId}`) */}
                            return (
                                <div style={showMobile ? {margin: "3px" } :{marginLeft: 4, marginRight: 4}} key={`${Date.parse(date)}${student.studentId}`}>
                                    <StudentCircle
                                        key={`${student.studentId}${Date.parse(date)}`}
                                        color={student.color}
                                        circleSize={showMobile ? 10 : 12}
                                        creditsCountSize={25}
                                        textVisible={false}
                                        creditsVisible={false}
                                        textSize={8}
                                    />
                                </div>
                            )
                        }
                })}
          </div>
      </div>
    );
  };