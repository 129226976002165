import translate from '@translate';
import React from 'react';

export default function StudentCircle({circleSize, creditsCountSize, textSize, textVisible=true, color, credits, creditsVisible=true}) {

    const styles = {
        circle: {
            borderRadius: 50,
            backgroundColor: color,
            width: circleSize,
            height: circleSize,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        creditCount: {
            fontSize: creditsCountSize,
            color: "#fff",
            lineHeight: 0.4,
            margin: 5,
            fontWeight: "bold",
        },
        text: {
            fontSize: textSize,
            color: "#fff",
            lineHeight: 0.4,
            margin: 5,
            fontWeight: "bold",
            textTransform: "uppercase",
        }
    }

    return (
        <div style={styles.circle}>
            {creditsVisible && <p style={styles.creditCount}>{credits}</p>}
            {textVisible && <p style={styles.text}>{translate("credits")}</p>}
        </div>
    )
}
