import { getStudentsOnEvent } from ".";

export const getLatestEventWithStudents = (events, students) => {
  let soonestEventWithStudents

  for (let event of events) {
    let studentsOnEvent = getStudentsOnEvent(event, students)
      if(studentsOnEvent.length) {
          soonestEventWithStudents = event
          break
      }
  }
  return soonestEventWithStudents
};