export const filterEvents = (events, students, filterValues, type) => {
    const filterStudents = filterValues.students;
    // console.log('events before filter', events);
    if (type === 'categories' && filterValues.categories) {
        const eventStates = filterValues.categories;
        const schoolStates = filterValues.schools;
        const studentStates = filterValues.students;
        const eventKeys = eventStates && Object.keys(eventStates);
        const schoolKeys = schoolStates && Object.keys(schoolStates);
        const studentKeys = studentStates && Object.keys(studentStates);
        const categoryNames = eventKeys && eventKeys.filter((key) => {
            return eventStates[key]
        })
        const schoolNames = schoolKeys && schoolKeys.filter((key) => {
            return schoolStates[key]
        })
        const studentNames = studentKeys && studentKeys.filter((key) => {
            //console.log('key,',studentStates[key]);
            return studentStates[key]
        })
       // console.log('studentNames', filterValues.students);
        let filteredEvents = [];
        let filteredStudents = [];
        schoolNames && studentNames && studentNames.forEach((name) => {
            const fullName = name.split("_");
            let foundStudent = students && students.filter((student) =>
                student.studentFirstName === fullName[0]
                && student.studentLastName === fullName[1]
                && schoolNames.some(school => school === student.studentSchool)
            )
            // console.log(foundStudent)
            foundStudent.length && filteredStudents.push(foundStudent[0])
        })
        schoolNames && categoryNames && categoryNames.forEach((name) => {
            let foundEvents = events && events.filter((event) =>
                event.eventCategory === name
                && schoolNames.some(school => school === event.school || event.school === 'Select All')
                && filteredStudents.some(student => student.studentSchool === event.school || event.school === 'Select All')
            )
            // console.log(foundStudent)
            foundEvents.length && foundEvents.forEach(event => filteredEvents.push(event))
        })
       // console.log('after filter', filteredEvents);
        return filteredEvents
    }
    if (!events && filterStudents && type === 'students') {
        const studentStates = filterValues.students;
        const schoolStates = filterValues.schools;
        const keys = Object.keys(studentStates);
        const schoolKeys = Object.keys(schoolStates);
        const studentNames = keys.filter((key) => {
            return studentStates[key]
        })
        const schoolNames = schoolKeys.filter((key) => {
            return schoolStates[key]
        })
        // console.log("schools", schoolNames)
        let filteredStudents = [];
        studentNames.forEach((name) => {
            const fullName = name.split("_");
            let foundStudent = students.filter((student) =>
                student.studentFirstName === fullName[0]
                && student.studentLastName === fullName[1]
                && schoolNames.some(school => school === student.studentSchool)
            )
            // console.log(foundStudent)
            foundStudent.length && filteredStudents.push(foundStudent[0])
        })
        return filteredStudents
    }
}
