import StudentCircle from "@components/student-circle";
import { PRIMARY } from '@constraints';
import { Context } from '@context';
import { checkIfStudentIsOnEvent, formatDate, getLatestEventWithStudents, getTotalCreditReward, orderEventsByDate } from '@functions';
import { Button } from '@mui/material';
import translate from '@translate';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { convertMilitaryToStandard } from '../../../../../functions';

export default function Events({styles}) {
    const navigate = useNavigate();
    const { futureEventsFiltered, students } = useContext(Context);

    const [latestEvent, setLatestEvent] = useState()

    useEffect(() => {
        if(futureEventsFiltered.length && students.length) {
            setLatestEvent(getLatestEventWithStudents(orderEventsByDate(futureEventsFiltered), students))
        }
    },[futureEventsFiltered, students]);
    
    if(students.length) {
        return (
            <div style={styles.cardContainer}>
                <p style={styles.titleEvents}>{translate("events")}</p>
                {latestEvent &&
                <div style={styles.eventContentContainer}>
                    <div style={styles.eventImageContainer}>
                        <img style={styles.eventImage} src={latestEvent.image} alt="fireSpot"/>
                    </div>
                    <div style={styles.eventInfoContainer}>
                        <div style={styles.eventTextContainer}>
                            <p style={styles.eventDate}>{formatDate(latestEvent.dateString)}</p>
                            <p style={styles.eventName}>{latestEvent.name.toUpperCase()}</p>
                            <p style={styles.eventSchool}>{latestEvent.school === 'Select All' ? 'All Schools' : latestEvent.school.toUpperCase()}</p>
                            <p style={styles.eventTime}><strong>{translate("start time")}</strong>{`: ${convertMilitaryToStandard(latestEvent.startTime)}`}</p>
                            <p style={styles.eventTime}><strong>{translate("end time")}</strong>{`: ${convertMilitaryToStandard(latestEvent.endTime)}`}</p>
                        </div>
                        <div style={styles.creditsCircleContainer}>
                            <div style={styles.dotRow}>
                                {students.map((student) => {
                                    if(checkIfStudentIsOnEvent(latestEvent, student)) {
                                        return (
                                            <div style={{marginLeft: 3, marginRight: 3}} key={student.studentId}>
                                                <StudentCircle 
                                                    color={student.color}
                                                    circleSize={10}
                                                    creditsCountSize={25}
                                                    textVisible={false}
                                                    creditsVisible={false}
                                                    textSize={8}
                                                    credits={latestEvent.reward}
                                                />
                                            </div>    
                                        )
                                    }
                                })}
                            </div>
                            <div style={{display: "flex"}}>
                                { // <p style={styles.creditNumber}>{`${getTotalCreditReward(latestEvent, students)} `}</p>
                                }
                                <p style={styles.creditNumber}>{`${latestEvent.reward}`}</p>
                                <p style={styles.creditText}>{translate("credits")}</p>
                            </div>
                        </div>
                    </div>
                </div>    
                }
                <div style={styles.viewEventsButtonContainer}>
                    <Button variant="outlined" style={{borderRadius: 0, border: `1.5px solid ${PRIMARY}`}} onClick={() => navigate("/home/event-list")}>{translate("view events")}</Button>
                </div>
            </div>
        )
    } else {
        return (
            <div style={styles.cardContainer}>
            </div>
        )
    }

}
