import React, { useContext } from "react";
import { Typography } from '@mui/material';
import { theme } from '../../utils/theme';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Divider } from '@mui/material';
import StudentCircle from "@components/student-circle";
import { PieChart } from 'react-minimal-pie-chart';
import { useNavigate } from "react-router-dom";

import { Context } from '@context';
import { useStyles, styles } from './calendarStyles'
     
export const DetailListItem = ({event, handleDate, selectedDate}) => {
    const { students } = useContext(Context)
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();

    const showMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const eventStudents = students.filter((student) => event.school === student.studentSchool || event.school === 'Select All')
    // console.log('eventStudents', eventStudents);
    const studentList = eventStudents.map((student) => {
        return { title: `${student.studentFirstName} ${student.studentLastName}`, value: 1, color: student.color }
    })

    return (
        <>
            <div className={classes.listItemWrapper} onClick={() => navigate({pathname: `/home/event-details/${event.id}`})}>
                <div className={classes.detailWrapper}>
                    <div className={classes.imageWrapper}>
                        <img src={event.image} className={classes.image} alt="thumbnail for event" />
                    </div>
                    <div className={classes.eventDescription}>
                        <Typography variant={"body1"} color="secondary" sx={styles.date}>{handleDate(selectedDate, 1)}</Typography>
                        <Typography variant={"body2"} color="secondary" sx={styles.body}>{event.name}</Typography>
                        <Typography variant={"body2"} color="secondary" sx={styles.body}>{event.school === 'Select All' ? 'All Schools' : event.school}</Typography>
                    </div>
                </div>
                <div className={classes.detailWrapper}>
                    <div className={classes.pointsWrapper}>
                            {eventStudents && eventStudents.length === 1 &&
                                (
                                    <div className={classes.circle}>
                                        <StudentCircle
                                            key={`${studentList[0].studentId}${Date.parse(selectedDate.date)}`}
                                            color={studentList[0].color}
                                            circleSize={showMobile ? 45 : 60}
                                            creditsCountSize={showMobile ? 20 : 30}
                                            textVisible={showMobile ? false :true}
                                            creditsVisible={true}
                                            textSize={10}
                                            credits={event.reward}
                                        />
                                    </div>
                                )
                            } 
                            {eventStudents && eventStudents.length > 1 &&
                                (
                                    <>
                                        <div className={classes.pieCircle}>
                                            <PieChart
                                                startAngle={45}
                                                data={studentList}
                                            />
                                            <div className={classes.pieText}>
                                                <StudentCircle
                                                    key={`${studentList[0].studentId}${Date.parse(selectedDate.date)}`}
                                                    color={"none"}
                                                    circleSize={showMobile ? 45 : 60}
                                                    creditsCountSize={showMobile ? 20 : 30}
                                                    textVisible={showMobile ? false :true}
                                                    creditsVisible={true}
                                                    textSize={10}
                                                    credits={event.reward}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                    </div>
                    <div className={classes.arrowWrappper}>
                        <ArrowForwardIosIcon color="secondary" />
                    </div>
                </div>
            </div>
            <Divider />
        </>
    )
}