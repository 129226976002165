import { db } from "@database";
import { collection, onSnapshot } from "firebase/firestore";

export const subscribeCreditHistory = (parentId, setCreditHistory) => {
  try {
    const creditHistoryRef = collection(db, `parents/${parentId}/credits-history`)
    onSnapshot(creditHistoryRef, (snapshot) => {
        if(snapshot.docs.length > 0){
          const creditHistory = [];
          snapshot.docs.forEach((credit, idx) => {
            creditHistory.push(credit.data());
            creditHistory[idx].id = credit.id
          })
          // console.log("CREDIT HISTORY", creditHistory);
          setCreditHistory(creditHistory);
        }
      })
  } catch (error) {
    console.log("ERROR SUBSCRIBING TO CREDIT HISTORY COLLECTION", error);
  }
};