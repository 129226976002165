import { Box, Button, TextField, Typography } from '@mui/material';
import {
  getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, updatePassword
} from "firebase/auth";
import { useState } from 'react';
import PasswordChecklist from "react-password-checklist";
import { useNavigate } from "react-router-dom";
import translate from '../../i18n/translate';
import styles from './styles';

export const PasswordReset = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [newPassword,  setNewPassword] = useState("");
  const [confirmPassword,  setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState(false);
 
  const onChangePassword = async () => {
    await signInWithEmailAndPassword(auth, email, password)
    .then( async(userCredential) => {
        // console.log(userCredential);
        navigate("/home/dashboard");
        const user = auth.currentUser;
        await updatePassword(user, newPassword);
        // const credential = EmailAuthProvider.credential(
        //     email,
        //     newPassword
        // )
        // await reauthenticateWithCredential(user, credential);
        // await signInWithEmailAndPassword(auth, email, newPassword);
    })
    .catch((error) => {
      console.log(error);
      alert('There was an issue with your email and temporary password. Please make sure these are correct and try again.');
    });
  }

  const onResetPassword = () => {
    sendPasswordResetEmail(auth, email)
    .then(() => {
      alert('Password reset email sent, Please check your email');
    })
    .catch((error) => {
      console.log(error);
    });
  }

  return (
    <div style={styles.mainContainer}>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '30ch' },
        }}
        noValidate
        autoComplete="off"
        style={{display: "flex", flexDirection: "column", alignItems: "center"}}
      >
        <Typography variant="h6" gutterBottom component="div" color="secondary" style={{display: "flex", justifyContent: "center"}}>
            {translate('resetpassword')}
        </Typography>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}> 
                <TextField
                    fullWidth 
                    id="outlined-basic" 
                    label="Email Address" 
                    variant="outlined" 
                    color="secondary"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    style={{marginBottom: 10}}
                />
                <TextField
                    fullWidth 
                    id="outlined-basic" 
                    label="Temporary Password" 
                    variant="outlined" 
                    color="secondary"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    style={{marginBottom: 10}}
                />
                <TextField
                    fullWidth 
                    id="outlined-basic" 
                    label="New Password" 
                    variant="outlined" 
                    color="secondary"
                    type="password"
                    onChange={(e) => setNewPassword(e.target.value)}
                    value={newPassword}
                    style={{marginBottom: 10}}
                />
                <TextField
                    fullWidth 
                    id="outlined-basic" 
                    label="Confirm New Password" 
                    variant="outlined" 
                    color="secondary"
                    type="password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    style={{marginBottom: 10}}
                />
                <PasswordChecklist
                  rules={["minLength","specialChar","number","capital", "match"]}
                  minLength={6}
                  value={newPassword}
                  valueAgain={confirmPassword}
                  onChange={(isValid) => setIsValid(isValid)}
                  style={{marginBottom: 20}}
                />
            </div>
            <Button variant="contained" onClick={onChangePassword} disabled={!isValid || !email || !password}>Change Password</Button>
            <p></p>
        </div>
      </Box>
    </div>
  );
};