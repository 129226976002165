import StudentCircle from "@components/student-circle";
import { Context } from '@context';
import { Box, Button, Dialog, Link, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DirectionsRenderer, GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { convertMilitaryToStandard, getCheckInStatus, getEventAddress, getEventDetails, getStudentsOnEvent, pickRandomStudent, validateCheckIn } from "../../functions";
import translate from '../../i18n/translate';
import { updateParentCredits, updateStudentCredits } from "../../services/firebase";
import { styles, useStyles } from './eventStyles';
import {Marker,APIProvider} from '@vis.gl/react-google-maps';
import { getAllEventsWithStudents } from "../../../src/functions/getAllEventsWithStudents"

const mapContainer = {
  width: '50%',
  height: '400px',
};
const mapContainerMobile = {
    width: '80%',
    height: '300px',
};
const mapOptions = {
  panControl: false,
  fullscreenControl: false,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  overviewMapControl: false,
  rotateControl: false,
};
  
export const EventDetailMap = () => {

  const [openDialog, setOpenDialog] = useState(false);
  const {getData,setTrue,arrayEvents, allStudents, locale, userLocation, parent, allEvents, creditHistory, loading, isAllEventsSet } = useContext(Context);
  const[address, setAddress] = useState('');
  const[directions, setDirections] = useState(null);
  const [event, setEvent] = useState({
    id: "",
    school: "",
    address: "",
    description: "",
    eventCategory: "",
    endTime: "",
    eventLat: "",
    eventLong: "",
    image: "",
    name: "",
    reward: "",
    startTime: "",
    virtual: false,

  });
  const [eventStudents, setEventStudents] = useState([]);
  const [alertMessage, setAlertMessage] = useState(false);

  const [randomStudent, setRandomStudent] = useState(null);
  const [checkedIn, setCheckedIn] = useState(null);
  const [map, setMap] = useState(null)
  const navigate = useNavigate();
  const center = {
    lat: parseFloat(event?.eventLat),
    lng: parseFloat(event?.eventLong)
  };

  const params = useParams();
  const theme = useTheme();
  const location = useLocation();
  const showMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyACF4K9AnAzUndTUicyitUcNYBXMqS9wHk", //dev
    // googleMapsApiKey: "AIzaSyC3ohrcX5L3CoYXkwYJzM1TegWoK54fovc", //prod
  });

  useEffect(() => {
      loadEventData();
  }, [allEvents, allStudents, creditHistory])

  useEffect(() => { //this is used when you refresh the page or scan the qrcode, it will repull the data for allEvents
    if(allEvents.length === 0)
    getData();
}, [])

  useEffect(() => {
    if (alertMessage) {
      alert("Event not found");
      setAlertMessage(false); // Reset alertMessage after showing the alert
      navigate('/home/dashboard')

    }
  }, [alertMessage]);
  
  const loadEventData = async () => {

    const eventId = params.id;
    //console.log("Params ID: ", eventId);
    //console.log("All Events: ", allEvents);
    //console.log("All Students: ", allStudents);
    //console.log("Credit History: ", creditHistory);
    //console.log("Credit History: ", creditHistory);
    //console.log("arrayEvents",arrayEvents)
    //console.log("setTrue",setTrue)

    
    const eventDetails = await getEventDetails(allEvents, eventId);
    if(allEvents.length !== 0){

      if (!eventDetails) {//If there is no event for parent
          alert("Event details not found");
          setAlertMessage(true);
          return;
      }
      const checkInStatus = await getCheckInStatus(creditHistory, eventId);
      const studentsOnEvent = getStudentsOnEvent(eventDetails, allStudents);
      const randomPickedStudent = pickRandomStudent(studentsOnEvent);
      setEvent(eventDetails);
      setCheckedIn(checkInStatus);
      setEventStudents(studentsOnEvent);
      setRandomStudent(randomPickedStudent);
      //const eventAddress = !eventDetails.virtual ? await getEventAddress(eventDetails) : '';//if not an online event, wait for event detail, else produce empty string
      //setAddress(eventAddress);
    } else {
      //alert('me')
      setAlertMessage(true); //if the parent scans the wrong barcode, to send this 
    };
  };
    
  const onMapLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const handleDate = (date, option) => {
    const dateOptions = [{ month: 'long', day: 'numeric' }, { month: 'short', day: 'numeric' }];
    let selection = new Date(date)
    return selection.toLocaleDateString(locale, dateOptions[option])
  };
 
  const onCheckIn = async () => {
    const validCheckIn = await validateCheckIn(event, userLocation, checkedIn);

    if (validCheckIn) {
      const student = eventStudents.length > 1 ? randomStudent : eventStudents[0];
      if (student) {
        const parentId = parent.parentId;
        const studentId = student.studentId;
        await updateParentCredits(parentId, event, eventStudents);
        await updateStudentCredits(parentId, studentId, event.reward);
      };
      return setOpenDialog(true); 
    }
  };

  // const getDirection = () => {
    
  //   const origin = { lat: userLocation.coords.latitude, lng: userLocation.coords.longitude };
  //   const destination = { lat: parseFloat(event?.eventLat), lng:  parseFloat(event?.eventLong)};
  //   const directionsService = new window.google.maps.DirectionsService();
  //   directionsService.route(
  //     {
  //         origin: origin,
  //         destination: destination,
  //         travelMode: window.google.maps.TravelMode.DRIVING,
  //     },
  //     (result, status) => {
  //         if (status === window.google.maps.DirectionsStatus.OK) {
  //             // console.log(result)
  //             setDirections(result);
  //         } else {
  //             console.error(`error fetching directions ${result}`);
  //         }
  //     }
  //   );
  // };

  
  const renderMap = () => {

    return (
    <GoogleMap
      options={mapOptions}
      mapContainerStyle={showMobile ? mapContainerMobile : mapContainer}
      center={center}
      zoom={15}
      onLoad={onMapLoad}
      onUnmount={onUnmount}
    >
      {directions === null && <Marker
        position={{lat: parseFloat(event?.eventLat), lng: parseFloat(event?.eventLong)}}
      />
    
      }
   
      <div style={styles.addressContainer}>
        <Typography variant={"h6"} sx={styles.addressTitle}>{translate('Address')}</Typography>
        <Typography sx={styles.address}>{event.address}</Typography>
        <a href={`http://maps.google.com/?q=${event.address}`} target="_blank">
          <Button variant="contained" color="primary" sx={styles.btnDirection}>{translate('Get Directions')}</Button>
        </a>
      </div>
    </GoogleMap>)
  };

  const renderStudentCircle = () => {
    if (eventStudents.length) {
      return (
        <div>
          {eventStudents && eventStudents.length === 1 &&
            (
              <div className={classes.circle}>
                <StudentCircle
                  key={`${eventStudents[0]?.studentId}${Date.parse(event.dateString)}`}
                  color={eventStudents[0]?.color}
                  circleSize={showMobile ? 45 : 60}
                  creditsCountSize={showMobile ? 20 : 30}
                  textVisible={showMobile ? false : true}
                  creditsVisible={true}
                  textSize={10}
                  credits={event.reward}
                />
              </div>
            )
          } 
          {eventStudents && eventStudents.length > 1 && randomStudent &&
            (
              <div className={classes.circle}>
                <StudentCircle
                  key={`${randomStudent.studentId}${Date.parse(event.dateString)}`}
                  color={randomStudent.color}
                  circleSize={showMobile ? 45 : 60}
                  creditsCountSize={showMobile ? 20 : 30}
                  textVisible={showMobile ? false : true}
                  creditsVisible={true}
                  textSize={10}
                  credits={event.reward}
                />
              </div>
            )
          }
        </div>
      )
    } else {
      return (
        <div>
          <Skeleton variant="circular" width={showMobile ? 45 : 60} height={showMobile ? 45 : 60} />
        </div>
      )
    }
  };
  
    
  return (
    
    <div className={classes.eventContainer}>
      
      {showMobile && event?.name &&
        <Typography variant={"body2"} color="secondary" sx={styles.title}>{location != null ? event?.name : ""}</Typography>
      } 
      
      {event?.image 
        ? <img src={event?.image} className={classes.image} alt="thumbnail for event" />
        : <Skeleton variant="rectangular" width={"50%"} height={300} />
      }

      <div className={classes.listItemWrapper}>
        <div className={classes.eventDescription}>
            {event?.dateString 
              ? 
                <Typography variant={"body1"} color="error" sx={styles.date}>{handleDate(event?.dateString, 1)}</Typography>
              : <Skeleton variant="text" width={50}/>
            }
            {event?.startTime && event?.endTime ?                
                <Typography variant={"body1"} color="error" sx={styles.date}>{convertMilitaryToStandard(event?.startTime)} - {convertMilitaryToStandard(event?.endTime)}</Typography>
              : <Skeleton variant="text" width={50}/>
            }
            {event?.name 
              ? <Typography variant={"body2"} color="secondary" sx={styles.body}>{event?.name}</Typography>
              : <Skeleton variant="text" width={200}/>
            }
            {event?.school 
              ? <Typography variant={"body2"} color="secondary" sx={styles.body}>{event?.school === 'Select All' ? 'All Schools' : event?.school}</Typography>
              :<Skeleton variant="text" width={200}/>
            }
        </div>    
        <div className={classes.detailWrapper}>
          <div className={classes.pointsWrapper}>
            {renderStudentCircle()}
          </div>
        </div>
      </div>
      
      <div className={classes.description}>
        <hr />
        {event?.description 
        ? event.description  
        : [1, 1, 1, 1, 2].map((n, index) => (
            <Skeleton key={`${n}_${index}`} variant="text" width={n === 2 ? 200 : 300} />
          ))
      }

        {showMobile && event?.virtual === true &&
          <>
            <Typography variant={"body1"} color="error" sx={styles.date}>{translate('WEBSITE')}:</Typography>
            <Link href={location != null ? event?.website : ""} target="_blank" rel="noopener" variant={"body2"} color="error" sx={styles.date}>{location != null ? event?.website : ""}</Link>
          </>
        }
      </div>

      <div className={classes.mapView}>
        {event?.school && isLoaded && !event.virtual 
          ? 
          <APIProvider apiKey={'YAIzaSyACF4K9AnAzUndTUicyitUcNYBXMqS9wHk'}>{renderMap()}</APIProvider>
          : !showMobile &&
            <div className={classes.website}>
              <Typography variant={"body1"} color="error" sx={styles.date}>{translate('WEBSITE')}:</Typography>
              <Link href={location != null ? event?.website : ""} target="_blank" rel="noopener" variant={"body2"} color="error" sx={styles.date}>{location != null ? event?.website : ""}</Link>
            </div>
        }
        <div className={classes.optionsContainer}>
          <div className={classes.checkInTop}>
            <Typography variant={"h6"} color="error" sx={styles.title2}>{event?.virtual ? translate('onlineEvent') : translate('inPersonEvent')}</Typography>
          </div>
            {checkedIn !== null 
              ?
              <div className={classes.checkIn}>
                {checkedIn 
                  ? <div style={{textAlign: "center"}}>{translate('thankYouForCheckIn')}</div>
                  : <Button className={classes.button} variant="contained" onClick={onCheckIn}>{translate('CHECK IN')}</Button>
                }
              </div>
              : <Skeleton variant="rectangular" width={"100%"} height={40} />
            }
            {showMobile &&
              <div className={classes.checkInBottom}>
                <Button variant="text" color="error" onClick={() => navigate("/home/calendar")}>{translate('VIEW CALENDAR')}</Button>
              </div>
            }
      
        </div>
      </div> 

      <Dialog
        fullScreen={false}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <Box sx={{ border: 2, borderColor: 'secondary.main' }} className={classes.dialogBox}>
          <Typography variant={"body1"} color="secondary" sx={styles.date}>{translate('thankYouForCheckIn')}</Typography>
          <Typography variant={"body2"} color="error" sx={styles.date}>{location != null ? event?.name : ""}</Typography>
        </Box>
      </Dialog>
    </div>
  );
}