import DashboardDesktop from '@components/dashboard/layouts/DashboardDesktop';
import DashboardMobile from '@components/dashboard/layouts/DashboardMobile';
import DashboardTablet from '@components/dashboard/layouts/DashboardTablet';
import { Context } from '@context';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import { useContext } from 'react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';



const useStyles = makeStyles(theme => ({
  container: {
    // [theme.breakpoints.down('sm')]: {
    //   backgroundColor: theme.palette.primary.main,
    // },
  },
}));

export const Dashboard = () => {
  const { getData, students } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();
  // useEffect(() => {
  //   getData();
  // },[])
  

  


  const classes = useStyles();
  const theme = useTheme();
  const showMobile = useMediaQuery(theme.breakpoints.down('md'));
  const showDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  
  return (
    <div className={classes.container}>
      {showMobile ? <DashboardMobile size="sm"/> : showDesktop ? <DashboardDesktop/> : <DashboardTablet size="md"/>}
    </div>
  );
};