export const subscribeUserLocation = (setUserLocation) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function(position) {
      // console.log('position', position.coords.latitude, position.coords.longitude);
      setUserLocation(position);
    }, (error) => {
      console.log('error', error);
    });
    navigator.geolocation.watchPosition(function(position) {
      setUserLocation(position);
    },(error) => {
      console.log('error', error);
    });
  } else {
    alert('Please enable location in your browser settings');
  }
};