import { db } from "@database";
import { doc, onSnapshot } from "firebase/firestore";

export const subscribeParentData = (parentId, setParent) => {
  try {
    const parentRef = doc(db, `parents/${parentId}`)
    onSnapshot(parentRef, (parent) => {
      const parentData = parent.data();
      if(!parentData.totalCredits) {
        parentData.totalCredits = 0
      } 
      if(!parentData.creditsRedeemed) {
        parentData.creditsRedeemed = 0
      };
      setParent(parentData);
    })
  } catch (error) {
    console.log("ERROR SUBSCRIBING TO PARENT DOCUMENT", error);
  }
};
