import Ticker from "@components/ticker";
import { PRIMARY } from "@constraints";
import { Context } from "@context";
import translate from "@translate";
import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import RewardsGrid from '../rewards-grid';
export default function RewardsMobile({size}) {
    const styles = {
        link: {
            paddingLeft: "2%", 
            textTransform: "uppercase", 
            color: PRIMARY, 
            fontWeight: "bold", 
            marginTop: 0,
            marginBottom: 35,
            cursor: 'pointer',
        }
    }

    const { parent } = useContext(Context);
    const navigate = useNavigate();

    return (
        <div>
            <div style={{marginBottom: 20,}}>
               {parent && <Ticker text={translate("total available credits")} count={parent.totalCredits - parent.creditsRedeemed}/>}
            </div>
            <div style={{display: "flex", justifyContent: "center", margin: 0,}}>
                <p style={styles.link} onClick={()=>navigate('/home/credit-history', {state: {isOpen: false}})}>{translate("VIEW CREDIT HISTORY")}</p>
            </div>
            <div style={{display: "flex", justifyContent: "center", margin: 0,}}>
                <p style={styles.link} onClick={()=>navigate('/home/credit-history', {state: {isOpen: true}})}>{translate("requestCredit")}</p>
            </div>
            <RewardsGrid size={size}/>
        </div>
    )
}
