import React, {useEffect, useState} from 'react';
import { db } from "@database";
import { collection, getDocs, query, where } from "firebase/firestore";
import Resource from '../../components/resource';
import { makeStyles } from "@mui/styles";
import { Context } from '@context';
import { useContext } from 'react';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%'
  }
});

export const Resources = () => {
  const [data, setData] = useState([]);
  const classes = useStyles();
  const { students } = useContext(Context);

  useEffect(() => {
    
    // if(data.length <= 0)
      fetchResources();
    // return () => setData([]);
  },[])
  // console.log('students', students);
  const unique = [...new Set(students.map(item => item.studentSchool))];
  unique.push('Select All');
  // console.log('unique', unique);

  const fetchResources = async() => {
    try{
      // console.log('called res');
      const colRef = collection(db, 'resources');
      const q = query(colRef, where('schoolName', 'in', unique));
      const colSnap = await getDocs(q);
      const tempData = []
      colSnap.docs.forEach((doc, idx) => { 
        tempData.push(doc.data());
        tempData[idx].id = doc.id;
      })
      setData(tempData);
    } catch(e) {
      console.log(e);
    }

  }
   
  return (
    <div className={classes.container}>
      {data.length > 0 && data.map(item => <Resource item={item} key={item.id}/>)}
    </div>
  );
}