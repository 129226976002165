
import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { makeStyles } from '@mui/styles';
import { Context } from "../../../context";
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import translate from '@translate';
import useMediaQuery from '@mui/material/useMediaQuery';

import { StudentsFilter } from "./StudentsFilter"

const useStyles = makeStyles(theme => ({
    container: {
        background: theme.palette.secondary.main,
    },
    filterTitleContainer: {
        width: "99%",
        height: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 25
    },
    filterFooterContainer: {
        width: "99%",
        height: 100,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        paddingBottom: 25,
    },
    filterTitle: {
        flex: 1,
        textAlign: 'center',
        color: theme.palette.neutral.main,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontFamily: 'Poppins'
    },
    filterSection: {
        flex: 1,
        width: "80%",
        // height: 50,
        paddingRight: 20,
        paddingLeft: 10,
    },
    filterHeading: { 
        textAlign: 'left',
        color: theme.palette.neutral.main,
        fontWeight: 800,
        textTransform: 'uppercase',
        fontFamily: 'Poppins'
    },
    reset: {
        mt: 3,
        color: theme.palette.neutral.main,
        "&:hover": {
            cursor: 'pointer',
            opacity: "0.8"
        }
    },
  }));
  

export const MobileFilterMenu = () => {
    const { mobileFilterOpen, setMobileFilterOpen, filterValues, setFilterValues } = useContext(Context);
    
    const theme = useTheme();
    const classes = useStyles();

    const showOnDesktop = useMediaQuery(theme.breakpoints.up('xl'));
    const showOnTabletHoriz = useMediaQuery(theme.breakpoints.up('md'));
    const showOnTabletVert = useMediaQuery(theme.breakpoints.up('sm'));
    const showOnMobile = useMediaQuery(theme.breakpoints.up('xs'));

    const styles = {
        drawerBoxDesk: {
            width: '15vw',
            height: '100%',
            background: theme.palette.secondary.main,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        drawerBoxTabletHoriz: {
            width: '25vw',
            height: '100%',
            background: theme.palette.secondary.main,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        drawerBoxTabletVert: {
            width: '35vw',
            height: '100%',
            background: theme.palette.secondary.main,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        drawerBoxMobile: {
            width: '55vw',
            height: '100%',
            background: theme.palette.secondary.main,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        titleFont: {
            fontFamily: 'Poppins',
            fontWeight: 800,
            textTransform: 'uppercase',
        },
        resetFont: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            textTransform: 'uppercase',
            textDecoration: 'underline',
        }
    }

    const handleFilterReset = () => {
        let studentNames = {};
        let schoolNames = {};
        let categoryNames = {};
        let newValues = { ...filterValues };

        if (newValues.students) {
            Object.keys(newValues.students).forEach(key => {
                studentNames[key] = true;
            });
        }

        if (newValues.schools) {
            Object.keys(newValues.schools).forEach(key => {
                schoolNames[key] = true;
            });
        }

        if (newValues.categories) {
            Object.keys(newValues.categories).forEach(key => {
                categoryNames[key] = true;
            });
        }

        setFilterValues({
            students: studentNames,
            schools: schoolNames,
            categories: categoryNames
        });
    };


    return (
        <SwipeableDrawer
            PaperProps={{
                sx: {
                backgroundColor: theme.palette.secondary.main,
                color: "red",
                }
            }}
            anchor={'right'}
            open={mobileFilterOpen}
            disableSwipeToOpen={true}
            onClose={()=>setMobileFilterOpen(false)}
            onOpen={()=>setMobileFilterOpen(true)}
            disableDiscovery={true}
            classes={{paper: classes.container}}
        >
            <Box
            
            sx={showOnDesktop ? styles.drawerBoxDesk : showOnTabletHoriz ? styles.drawerBoxTabletHoriz : showOnTabletVert ? styles.drawerBoxTabletVert : styles.drawerBoxMobile}
            role="presentation"
            >
                <div className={classes.filterTitleContainer}>
                    <Typography variant="h6" component="div" className={classes.filterTitle} sx={styles.titleFont}>
                        {translate('filters')}
                    </Typography>
                </div>
                <div className={classes.filterSection} >
                    <Typography variant="body1" className={classes.filterHeading} sx={styles.titleFont}>
                        {translate('students')}
                    </Typography>
                    <div>
                        <StudentsFilter filterType={"students"} />
                    </div>
                </div>
                <div className={classes.filterSection} >
                    <Typography variant="body1" className={classes.filterHeading} sx={styles.titleFont}>
                         {translate('schools')} 
                    </Typography>
                    <div>
                        <StudentsFilter filterType={"schools"} />
                    </div>
                </div>
                <div className={classes.filterSection} >
                    <Typography variant="body1" className={classes.filterHeading} sx={styles.titleFont}>
                        {translate('eventCategories')}
                    </Typography>
                    <div>
                        <StudentsFilter filterType={"categories"} />
                    </div>
                </div>
                <div className={classes.filterFooterContainer}>
                    <Typography variant="body1" className={classes.reset} sx={styles.resetFont} onClick={handleFilterReset} >{translate("resetFilters")}</Typography>
                </div>
            </Box>
        </SwipeableDrawer>
    );
}