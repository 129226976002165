import React, {useContext, useState, useEffect} from 'react';
import { Context } from '@context';
import { EventDetailMap } from "../../components/event-detail-map"
import { DesktopSideNav } from "../../components/navigation/desktopSideNav";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Header } from "../../components/header";

export const EventDetails = () => {
  const theme = useTheme();
  const showMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { getData } = useContext(Context);

  useEffect(() => {
    // getData();
  }, [])
  
  return (
    <div>
      {/* {!showMobile && <div>
      </div>} */}
      <div style={{ width: showMobile ? '100%' : `calc(100% - ${'5vw'})`, display: "flex", justifyContent: "flex-start" }}>
        <EventDetailMap />
      </div>
    </div>
  );
};