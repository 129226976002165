import React from 'react'
import StudentCard from '../../../student-card'
import { PRIMARY, SECONDARY } from '@constraints';
import TouchableOpacity from "@components/buttons/touchable";
import { useNavigate } from "react-router-dom";
import translate from '@translate';

export default function StudentList({ students }) {
    const navigate = useNavigate();

    const styles = {
        listContainer: {
            width: "96.5%",
            paddingLeft: 20,
        },
        seperator: {
            borderTop: "1px solid lightgray",
        },
        title: {
            color: SECONDARY,
            fontWeight: "bold",
            textTransform: "uppercase",
        },
        header: {
            color: SECONDARY,
            fontSize: 12,
            fontWeight: "bold",
            textTransform: "uppercase",
        },
    }

    return (
        <div style={styles.listContainer}>
            <div style={styles.seperator}/>
            <p style={styles.title}>{translate("students")}</p>
            <p style={styles.header}>{translate("credits")}</p>
            {
                students.map((student, index) => {
                    return (
                        <TouchableOpacity onClick={()=> navigate("../../home/event-list")} key={index}>
                            <StudentCard key={index} student={student} hasSeperator={index !== students.length - 1}/>
                        </TouchableOpacity>
                    )
                })
            }
        </div>
    )
}
