import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    palette: {
      primary: {
        main: "#B21414",
      },
      secondary: {
        main: "#063970"
      },
      neutral: {
        main: '#fff',
        secondary: '#C0C0C0',
        light: '#f2f2f2'
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });
