import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from "@mui/styles";
import translate from '@translate';
import CountUp from 'react-countup';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        minWidth: 212,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 15,
        paddingTop: 15,
    },
    wrapper: {
        width: '75%',
        height: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonContainer: {
        width: '90%',
        marginTop: 10,
        height: 90,
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    button: {
        width: '100%',
        marginTop: 10,
        marginBottom: 10,
        fontFamily: 'Poppins',
        fontWeight: 600,
        borderRadius: 0,
        borderWidth: 2,
        "&:hover": {
            borderWidth: 2,
        } 
    },
  }))

export const NavCredits = ({credits}) => {
    const classes = useStyles()
    const theme = useTheme()
    const navigate = useNavigate();

    const showOnDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    // console.log("NAV CREDITS", credits)

    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <Typography variant={"h2"}  sx={{  textAlign: 'center', color: theme.palette.secondary.main, fontFamily: 'Poppins', fontWeight: 600 }}>
                    <CountUp end={credits} duration={0.5} preserveValue={true}/>
                </Typography>
                <Typography variant={"caption"}  sx={{  textAlign: 'center', color: theme.palette.primary.main, fontFamily: 'Poppins', fontWeight: 800 }}>
                    {translate("TOTAL AVAILABLE CREDITS")}
                </Typography>
                <div className={classes.buttonContainer}>
                    <Button variant="contained" className={classes.button} onClick={() => navigate('/home/event-list')}>{translate("CHECK IN")}</Button>
                    <Button variant="outlined" className={classes.button} color="secondary" onClick={() => navigate('/home/credits')}>{showOnDesktop ? translate("REDEEM CREDITS") : translate('REDEEM')}</Button>
                </div>
            </div>
        </div>
    );
};
