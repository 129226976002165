import { db } from "@database";
import { doc, getDoc, setDoc } from "firebase/firestore";

export const updateStudentCredits = async (parentId, studentId, reward) => {
  try {
    const parentRef = doc(db, "parents", parentId);
    const studentRef = doc(parentRef, "students", studentId);
    const studentDoc = await getDoc(studentRef);
    const studentCredits = (studentDoc.exists() && studentDoc.data().studentCredits) ?? 0;
    const creditsNow = parseInt(studentCredits) + parseInt(reward);
    await setDoc(studentRef, {studentCredits: creditsNow}, {merge: true});
  } catch {
    console.log("Error updating student credits");
  }
};