import { FADEDGRAY, PRIMARY } from '@constraints';
import { Context } from "@context";
import LockIcon from '@mui/icons-material/Lock';
import { Button, Typography } from '@mui/material';
import translate from "@translate";
import { useContext, useEffect, useState } from 'react';
import { checkIfRedeemed } from '../../../../services/firebase';
import RedeemModal from "../../redeem-modal";
const RewardCardLocked = ({ reward, size="lg", status="locked", redemptions }) => {
    const { parent } = useContext(Context);
    const [modalOpen, setModalOpen] = useState(false);
    const [redeemed, setRedeemed] = useState(false);
   // const count= parent.totalCredits - parent.creditsRedeemed
   const totalCost = parent?.totalCredits - parent?.creditsRedeemed
    const enoughRewardCredit = reward.cost <= totalCost
    //console.log('parent', parent, reward);

    const styles = {
        image: {
            // width: size === "lg" ? "55%" : "70%",
            // height: "auto",
            objectFit: "cover",
            // maxWidth: 10,
            maxWidth: size === "lg" ? "55%" : "60%",
            maxHeight: size === "lg" ? "21vh" : size ==="md" ? "24vh" : "24vh",
            display: 'block',
        },
        imageLocked: {
            // width: size === "lg" ? "55%" : "70%",
            // height: "auto",
            objectFit: "cover",
            // maxWidth: 10,
            maxWidth: size === "lg" ? "55%" : "60%",
            maxHeight: size === "lg" ? "21vh" : size ==="md" ? "24vh" : "24vh",
            display: 'block',
            opacity: 0.5,
        },
        imageContainer: {
            width: "auto",
            height: size === "lg" ? "21vh" : size ==="md" ? "24vh" : "24vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: `2px solid ${FADEDGRAY}`,
            borderBottom: 0,
            overflow: 'hidden',
            position: 'relative',
        },
        rewardStatus: {
            fontWeight: "bold",
            fontSize: "1em",
            margin: 0,
            textTransform: "uppercase"
        },
        description: {
            fontSize: "0.9em",
            margin: 0,
            textTransform: "uppercase",
            marginTop: 2,
            marginBottom: 2,
        },
        cost: {
            fontSize: "1em",
            margin: 0,
            fontWeight: "bold",
        },
        bDisabled: {
            borderRadius: 0, 
            width: "100%", 
            height: size === "lg" ? 45 : size === "md" ? 45 : 35,
            backgroundColor: "lightgray",
        },
        bEnabled: {
            borderRadius: 0,
            width: "100%", 
            height: size === "lg" ? 45 : size === "md" ? 45 : 35,
        },
        bInActive: {
            borderRadius: 0,
            width: "100%", 
            height: size === "lg" ? 45 : size === "md" ? 45 : 35,
            backgroundColor: "rgb(6, 57, 112)",
        },
        bText: {
            color: "#fff", 
            fontWeight: "bold",
            fontSize: size === "lg" ? "1em" : "0.9em",
        },
        rContainer: {
            borderRadius: 0, 
            width: "100%", 
            //height: size === "lg" ? 45 : size === "md" ? 45 : 35,
            fontSize: size === "lg" ? 14 : size === "md" ? 11 : 10,
            backgroundColor: "#fff",
            paddingTop: 10,
            marginBottom: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        rText: {
            color: "#000", 
            fontWeight: "bold",
            fontSize: size === "lg" ? 12 : 11,
            textAlign: 'center',
            lineHeight: 1.1,
        },
        rSubText: {
            color: "#000", 
            fontWeight: "bold",
            fontSize: size === "lg" ? 10 : 9,
            textAlign: 'center',
            marginTop: '2px'
    
        },
        textContainer: {
            marginTop: 10,
            color: status === "locked" ? "lightgray" : PRIMARY,
        },
        icon: {
            color: "lightgray",
            width: "auto",
            height: "auto",
            maxWidth: size === "lg" ? "22%" : "30%",
            position: 'absolute',
            // objectFit: "cover",
        }
    }
    useEffect(() => {
       checkRedemptionStatus();
    }, [reward.id, redemptions])
    
    const checkRedemptionStatus = async () => {
        const redeemed = await checkIfRedeemed(parent, reward);
        if (redeemed) {
            setRedeemed(true);
            setModalOpen(false);
        };
    };

    if(status === "locked" && !redeemed) {
        return (
            <div style={{marginBottom: 20, maxWidth:500}}>
                <div style={styles.imageContainer}>
                    <img style={status === 'locked' ? styles.imageLocked : styles.image} src={reward.image} alt="fireSpot"></img>
                    <LockIcon sx={styles.icon}/>
                </div>
                
                    
               
                <Button  
                    disabled 
                    style={styles.bDisabled}
                >
                    <Typography style={styles.bText}>{translate("locked")}</Typography>
                </Button>
                <div style={styles.textContainer}>
                        <Typography style={styles.rewardStatus}>{`${Math.abs(reward.cost - (parent?.totalCredits - parent?.creditsRedeemed))} `}<strong>{translate("pointsToRedeem")}</strong></Typography>
                        <Typography style={styles.description}>{reward.description}</Typography>
                        <Typography style={styles.cost}>{`${reward.school} - ${reward.cost} `}<strong>{translate("credits")}</strong></Typography>
                </div>
            </div>
        )
    } 
    
    if (!redeemed) { //redeeming reward
        return (
            <div style={{marginBottom: 20, maxWidth:500}}>
                <div style={styles.imageContainer}>
                    <img style={styles.image} src={reward.image} alt="fireSpot"/>
                </div>
                <Button 
                    variant="contained"
                    onClick={() => {
                        setModalOpen(true);
                        //console.log('ModalOpen', modalOpen);
                      }}     
                    style={styles.bEnabled}
                >
                    <Typography style={styles.bText}>{translate("redeem now")}</Typography>
                </Button>
                 
                <div style={styles.textContainer}>
                    <Typography style={styles.rewardStatus}>{translate("unlocked")}</Typography>
                    <Typography style={styles.description}>{reward.description}</Typography>
                    <Typography style={styles.cost}>{`${reward.school} - ${reward.cost} `}<strong>{translate("credits")}</strong></Typography>
                </div>
                
                <RedeemModal open={modalOpen} setOpen={setModalOpen} reward={reward} size={size} checkIfRedeemed={checkIfRedeemed}/>
            </div>
        )
    } 
    if (enoughRewardCredit ) { //when reward is redeemed
        return (
        <div style={{marginBottom: 20, maxWidth:500}}>
            <div style={styles.imageContainer}>
                <img style={styles.image} src={reward.image} alt="fireSpot"/>
            </div>
            {reward.name === "Parent University Sticker" ? ( //Allow user to select the sticker once
            <Button 
                variant="contained"
                style={styles.bEnabled}     
                onClick={() => {
                    setModalOpen(false);
                    //console.log('ModalOpen', modalOpen);
                  }}                
            >
                <Typography style={styles.bText}>{translate("Lock")}</Typography>
            </Button>) : (
            <Button 
            variant="contained"
            style={styles.bEnabled}     
            onClick={() => {
                setModalOpen(true);
                //console.log('ModalOpen', modalOpen);
                }}                
            >
                <Typography style={styles.bText}>{translate("Redeem Again")}</Typography>
            </Button>
            )}
            <div style={styles.rContainer}>
                <Typography variant='subtitle2' color='secondary' sx={styles.rText}>{translate("redeemedReward")} {translate("checkEmail")} {translate("pickUpReward")}</Typography>
                <Typography variant='subtitle2' color='secondary' sx={styles.rSubText}>*{translate("mustHaveReciept")}</Typography>
                {reward.name !== "Parent University Sticker" ? (
                    <>
                        <Typography style={styles.description}><strong>{translate("descriptionTitle")}</strong>{reward.description}</Typography>
                        <Typography style={styles.cost}>{`${reward.school} - ${reward.cost} `}<strong>{translate("credits")}</strong></Typography>
                    </> 
                ) : null}
            </div>
            <RedeemModal open={modalOpen} setOpen={setModalOpen} reward={reward} size={size} checkIfRedeemed={checkIfRedeemed}/>

        </div>
        )
     } else {
        return (
            <div style={{marginBottom: 20, maxWidth:500}}>
                <div style={styles.imageContainer}>
                    <img style={status === 'locked' ? styles.imageLocked : styles.image} src={reward.image} alt="fireSpot"></img>
                    <LockIcon sx={styles.icon}/>
                </div>
                
                    
               
                <Button  
                    disabled 
                    style={styles.bDisabled}
                >
                    <Typography style={styles.bText}>{translate("locked")}</Typography>
                </Button>
                <div style={styles.textContainer}>
                        <Typography style={styles.rewardStatus}>{`${Math.abs(reward.cost - (parent?.totalCredits - parent?.creditsRedeemed))} `}<strong>{translate("pointsToRedeem")}</strong></Typography>
                        <Typography style={styles.description}>{reward.description}</Typography>
                        <Typography style={styles.cost}>{`${reward.school} - ${reward.cost} `}<strong>{translate("credits")}</strong></Typography>
                </div>
            </div>
        )
     }
}
export default RewardCardLocked;