import { SECONDARY } from '@constraints';
import { Context } from '@context';
import { db } from "@database";
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { deleteDoc, doc } from "firebase/firestore";
import React, { useContext, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import translate from '../../i18n/translate';


const Note = ({ note, onEdit, onRemove }) => {
  const { parent } = useContext(Context);
  const [openRemove, setOpenRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onConfirmRemove = async () => {
    setIsLoading(true);
    try {
      const parentRef = doc(db, 'parents', parent.parentId);
      const noteRef = doc(parentRef, 'notes', note.id);
      await deleteDoc(noteRef);
      onRemove(note.id); // Update the state in the parent component
    } catch (e) {
      console.log(e);
    } finally {
      setOpenRemove(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: 20, marginBottom: 20 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 5 }}>
          <Typography variant={"h6"} color={SECONDARY}>{note.subject}</Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: 50, cursor: 'pointer' }}>
            <EditIcon fontSize='small' onClick={() => onEdit(note)} />
            <RemoveCircleIcon fontSize='small' color='error' onClick={() => setOpenRemove(true)} />
          </div>
        </div>
        <Typography variant={"body2"} color={SECONDARY} style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
          <AccessTimeFilledIcon fontSize='small' />{note.dateString}
        </Typography>
        <Typography variant={"body1"}>{note.description}</Typography>
      </div>
      <Dialog open={openRemove} onClose={() => setOpenRemove(false)}>
        <DialogTitle>{translate("Remove Note")}</DialogTitle>
        <DialogContent>
          <Typography variant={"body2"} color={SECONDARY} style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
            {translate("Are you sure you want to remove this note?")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRemove(false)}>{translate("Cancel")}</Button>
          <Button onClick={onConfirmRemove} disabled={isLoading}>
            {isLoading ? translate('Removing...') : translate('Remove')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Note;
