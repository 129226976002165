import { PRIMARY, SECONDARY } from '@constraints';

export default {
    cardContainer: {
        borderWidth: 1,
        borderRadius: 2,
        backgroundColor: "#fff",
        width: "23vw",
        height: 405,
        marginRight: 20,
        boxShadow: "0.5px 0px 1.5px 1px lightgray",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
    },
    cardContainerApps: {
        borderWidth: 1,
        borderRadius: 2,
        backgroundColor: "#fff",
        width: "23vw",
        height: 375,
        marginRight: 20,
        boxShadow: "0.5px 0px 1.5px 1px lightgray",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
    },
    cardContainerRewards: {
        borderWidth: 1,
        borderRadius: 2,
        backgroundColor: "#fff",
        width: "23vw",
        height: 405,
        marginRight: 20,
        boxShadow: "0.5px 0px 1.5px 1px lightgray",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
    },
    iconContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "35%",
    },
    eventImageContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    iconContainerRewards: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "25%",
    },
    appleIcon: {
        width: "auto",
        height: "auto",
        maxWidth: "95%",
        position: "relative",
        top: 20
    },
    googleIcon: {
        width: "auto",
        height: "auto",
        maxWidth: "95%",
        position: "relative",
        bottom: 10
    },
    titleLinks: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        color: SECONDARY
    },
    textLinks: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 10,
        color: SECONDARY,
        fontSize: 15
    },
    titleEvents: {
        display: "flex",
        fontSize: 15,
        justifyContent: "center",
        fontWeight: "bold",
        color: PRIMARY,
        textTransform: "uppercase",
    },
    titleRewards: {
        display: "flex",
        fontSize: 15,
        justifyContent: "center",
        fontWeight: "bold",
        color: SECONDARY,
        textTransform: "uppercase",
    },
    viewEventsButtonContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: 5
    },
    viewRewardsButtonContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 10,
        paddingBottom: 5
    },
    redeemButtonContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        margin:0,
    },
    redeemButton: {
        width: "100%",
        height: 40,
        maxWidth: "100%",
    },
    rewardContainer: {
        width: "90%",
        maxWidth: "95%",
        marginTop: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    rewardImage: {
        width: "auto",
        height: 115,
        maxWidth: "80%",
        marginBottom: 90,
    },
    rewardInfoContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        height: 20,
    },
    rewardText: {
        color: PRIMARY,
        fontSize: 12,
        lineHeight: 0.5,
        margin: 1,
        display: '-webkit-box',          // Creates a block element box
        WebkitBoxOrient: 'vertical',     // Orients the box vertically
        WebkitLineClamp: 3,              // Limits the text to 3 lines
        overflow: 'hidden'               // Hides the overflow text
    },
    rewardTextBold: {
        color: PRIMARY,
        fontWeight: "bold",
        fontSize: 12,
        lineHeight: 0.5,
        marginRight: 4,
        textTransform: "uppercase",
    },
    eventContentContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "100%",
        width: "90%",
    },
    eventTextContainer: {
        
    },
    eventImage: {
        height: 180,
        width: "100%",
       // maxHeight: 190,
        objectFit: "cover",
        //marginTop:10
    },
    eventInfoContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginTop: 5
    },
    eventDate: {
        lineHeight: 0.5,
        fontWeight: "bold",
        color: PRIMARY,
        fontSize: 11,
    },
    eventName: {
        lineHeight: 1,
        fontSize: 13,
        color: SECONDARY,
    },
    eventSchool: {
        lineHeight: 1,
        color: SECONDARY,
        fontSize: 11,
    },
    eventTime: {
        lineHeight: 0.5,
        color: SECONDARY,
        fontSize: 11,
        textTransform: "uppercase",
    },
    creditsCircleContainer: {
        width: '35%',
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
        alignItems: 'center',
        // position: 'relative',
        // bottom: 0,
    },
    creditNumber: {
        marginTop: 5, 
        fontSize: 11,
        color: SECONDARY,
        textTransform: "uppercase",
        marginRight: 4
    },
    creditText: {
        marginTop: 5, 
        fontSize: 11,
        color: SECONDARY,
        textTransform: "uppercase",
    },
    dotRow: {
        // display: "flex",
        // justifyContent: "space-around"
    },
}