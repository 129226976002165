
import { ForgotPasswordForm } from '../../components/forgot-password';
import mainLogo from '../../res/logo.png';
import styles from './styles';
import React, { useContext} from 'react';
import {Button} from '@mui/material';
import { Context } from '@context';
import translate from '../../i18n/translate';
export const ForgotPassword = () => {
  const {setLocale } = useContext(
    Context
  );
  return (
    <div style={styles.mainContainer}>
      <Button onClick={() => setLocale('en-us')}>{translate('english')}</Button>
      <Button onClick={() => setLocale('es-es')}>{translate('spanish')}</Button>
      <img style={styles.logo} src={mainLogo} alt="fireSpot"/>
      <div style={styles.formContainer}>
        <ForgotPasswordForm/>
      </div>
    </div>
  );
};
