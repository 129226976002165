import { SECONDARY } from '@constraints';
import { Context } from '@context';
import { getRewardStatus } from "@functions";
import { Button } from '@mui/material';
import translate from '@translate';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";


export default function Rewards({styles}) {
    const navigate = useNavigate();
    const { rewards, onRedeem, parent } = useContext(Context);
    const [randomReward, setRandomReward] = useState();

    let pointsAvailale = parseInt(parent?.totalCredits) - parseInt(parent?.creditsRedeemed)
    //console.log(rewards)
    useEffect(() => {
        getRandomReward( rewards )
    },[rewards])

    useEffect(() => {
    },[randomReward, parent])

    const getRandomReward = (rewards) => {
        setRandomReward(rewards[Math.floor(Math.random() * rewards.length)]);
    }


    if(parent && rewards) {
        return (
            <div style={styles.cardContainerRewards}>
                <p style={styles.titleRewards}>{translate("rewards")}</p>
                {randomReward ? (
                <div style={{...styles.eventContentContainer,height:"100%"}}>
                        <div style={{...styles.iconContainerRewards,height:"auto"}}>
                            <img style={{...styles.rewardImage,marginBottom:"10px"}} src={randomReward.image} alt="fireSpot"/>
                        </div>
                    {/* <div style={styles.redeemButtonContainer}> */}
                        <Button style={styles.redeemButton} variant="contained" disabled={getRewardStatus(randomReward, pointsAvailale) === 'locked'} onClick={() => onRedeem(randomReward)}>{translate("redeem now")}</Button>
                    {/* </div> */}
                    <div style={{...styles.rewardInfoContainer, height:"auto"}}>
                        <p style={{...styles.rewardTextBold,lineHeight:"normal"}}>{translate(getRewardStatus(randomReward, parent.totalCredits))}</p>
                        <p style={{...styles.rewardText,lineHeight:"normal"}}>{randomReward.description}</p>
                        <div style={{display: "flex"}}>
                            <p style={{...styles.rewardTextBold,lineHeight:"normal", display: '-webkit-box', }}>{`${randomReward.cost} `}</p>
                            <p style={{...styles.rewardTextBold,lineHeight:"normal"}}>{translate("credits")}</p>
                        </div>
                    </div>
                    <div style={styles.viewRewardsButtonContainer}>
                    <Button variant="outlined" style={{borderRadius: 0, border: `1.5px solid ${SECONDARY}`}} color="secondary" onClick={() => navigate("/home/credits")}>{translate("view rewards")}</Button>
                </div>
                </div> ) :(
                     <div style={styles.viewRewardsButtonContainer}>
                     <Button variant="outlined" style={{borderRadius: 0, border: `1.5px solid ${SECONDARY}`}} color="secondary" onClick={() => navigate("/home/credits")}>{translate("view rewards")}</Button>
                 </div>
                )
                
                }
               
            </div>
        )
    } else {
        return (
            <div style={styles.cardContainerRewards}>
               
            </div>
        )
    }

}
