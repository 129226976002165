import MoreVertIcon from '@mui/icons-material/MoreVert';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useContext } from 'react';
import { useLocation } from "react-router-dom";
import { Context } from "../../context";
import translate from '../../i18n/translate';



import { MobileFilterMenu } from '../navigation/fliterMenu/MobileFilterMenu';

const useStyles = makeStyles(theme => ({
    icon: {
        fill: theme.palette.secondary.main
      }
  }));

export default function ButtonAppBar({setLanguageCookie}) {
    const { setMobileFilterOpen, setLocale, navList, language, setLanguage, parent } = useContext(Context);

    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();

    const checkActiveLink = () => {
        // console.log('location.pathname', location.pathname, navList)
        const currentPage = navList.filter(item => item.link === location?.pathname);
        // console.log('currentPage',currentPage);
        // console.log('parent', parent)
        // console.log("titles", titles)
        
        if(location?.pathname.includes("dashboard")) {
            return 'dashboard';
        }
        else if(currentPage.length > 0) {
            return translate(currentPage[0].name)
        }
        else if(location?.pathname.includes("event-details")) {
            return translate("Event Details");
        }
        else if(location?.pathname.includes("credit-history")) {
            return translate("Credit History");
        } else {
            return "No Page Name to Translate";
        }
    }

    const handleChange = (event) => {
        setLanguage(event.target.value);
        setLocale(event.target.value);
        setLanguageCookie(event.target.value);
      };

    const renderWelcome = () => {
        return (
            <div>
                {translate('welcome')}, {parent?.firstName}!
            </div>
        )
    }

    return (
        <Box sx={{ flexGrow: 1}}>
            <AppBar
                position="static"
                color='transparent'
                elevation={0}
                >
                <Toolbar sx={{ py: 3 }}>
                    <Typography variant="h4" component="div" sx={{ flexGrow: 1, textAlign: 'left', color: theme.palette.primary.main, fontFamily: 'Poppins', textTransform: 'capitalize' }}>
                        {checkActiveLink() === 'dashboard' ? renderWelcome() : checkActiveLink()}
                    </Typography>
                    <Box sx={{ minWidth: 75 }}>
                        <FormControl fullWidth variant="standard">
                            <Select
                            id="language-select"
                            value={language ?? null}
                            //   label="Age"
                            onChange={handleChange}
                            disableUnderline
                            style={{color: theme.palette.secondary.main, fontWeight: 600, fontSize: '0.9rem'}}
                            inputProps={{
                                        classes: {
                                            icon: classes.icon
                                        },
                                    }}
                            >
                            <MenuItem value={'en-us'}>{translate('english')}</MenuItem>
                            <MenuItem value={'es-es'}>{translate('spanish')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                <IconButton
                    size="large"
                    edge="end"
                    color="secondary"
                    aria-label="menu"
                    sx={{ ml: 2, mr: 1 }}
                    onClick={()=> {
                        // console.log("filter open");
                        setTimeout(()=>{setMobileFilterOpen(true)},200);
                        }
                    }
                >
                    <MoreVertIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <MobileFilterMenu />
        </Box>
    );
}
