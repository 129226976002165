import React from 'react';
import { createRoot } from 'react-dom/client'; // Update import statement
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import * as SentryBrowser from '@sentry/browser';
import { ErrorBoundaryExport as ErrorBoundary } from './ErrorBoundary.js';


Sentry.init({
  dsn: "https://750bf63d888f41a1bff15d77ae029b74@o954918.ingest.sentry.io/6333110",
  integrations: [SentryBrowser.browserTracingIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
