import CreditHistoryView from '@components/credit-history'
import { useContext, useEffect } from 'react';
import { Context } from '../../context';
export const CreditHistory = () => {
  
  return (
    <div>
      <CreditHistoryView />
    </div>
  );
};