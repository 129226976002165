import React from 'react';
import RewardsGrid from '../rewards-grid';
import { useNavigate } from "react-router-dom";
import translate from "@translate";
import { PRIMARY } from "@constraints";
import { Button } from '@mui/material';

export default function RewardsDeskop() {
    const navigate = useNavigate();

    return (
        <div style={{width: "88%"}}>
            <Button 
            variant="outlined"
            style={{marginLeft: "2%", textTransform: "uppercase", color: PRIMARY, fontWeight: "bold", marginBottom: 25, fontSize: 16}}
            onClick={()=>navigate('/home/credit-history', {state: {isOpen: false}})}
            >
                {translate("VIEW CREDIT HISTORY")}
            </Button>
            <Button 
            variant="outlined"
            style={{marginLeft: "2%", textTransform: "uppercase", color: PRIMARY, fontWeight: "bold", marginBottom: 25, fontSize: 16}}
            onClick={()=>navigate('/home/credit-history', {state: {isOpen: true}})}
            >
                {translate("requestCredit")}
            </Button>
            <RewardsGrid size="lg"/>
        </div>
    )
}
