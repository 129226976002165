import React from 'react';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { PRIMARY, SECONDARY } from '@constraints';
import { BorderColor } from '@mui/icons-material';
import translate, { useTranslate } from "../../i18n/translate";


const Resource = ({item}) => {
  // console.log(item);

  const openLink = () => {
    window.open(item.url);
  }

  return(
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: 20, borderBottom: "1px solid lightgray", margin: 10, padding: '20px 0px 0px 20px'}}>
      <Typography variant={"body2"} color={SECONDARY}>{item.name}</Typography>
      <Typography variant={"body1"}>{item.description}</Typography>
      <Typography variant={"body2"} color={SECONDARY}>{item.schoolName === 'Select All' ? 'All Schools' : item.schoolName}</Typography>
      <Button variant='text' onClick={openLink} color="error" >{translate("View More")}</Button>
    </div>
  )

}

export default Resource;