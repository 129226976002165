import { makeStyles } from "@mui/styles";
// import { theme } from '../../utils/theme';
// import { useTheme } from '@mui/material/styles';

export const useStyles = makeStyles(theme => ({
    eventContainer: {
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: 'column',
        // alignItems: 'left',
        // justifyContent: "left",
        // marginLeft: 20,
        paddingTop: 40,
        paddingLeft: 40,
        [theme.breakpoints.down('sm')]: {
           alignItems: 'center',
           justifyContent: "center",
           paddingLeft: 0,
        },
    },
    calendarDetailContainer: {
      width: "95%",
      display: "flex",
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: "flex-start",
    },
    detailViewButton: {
        display: 'flex',
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
    },
    controlContainer: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        paddingTop: 30,
        paddingBottom: 30,
    },
    eventListContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    noEventContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        paddingTop: 40,
    },
    eventDescription: {
        paddingBottom: 10,
        paddingTop: 10,
    },
    listItemWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '80%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '50%',
            alignItems: 'left',
        },
    },
    detailWrapper: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        
    },
    image: {
        maxWidth: '50%',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 10,
            maxWidth: '80%'
          },
        maxHeight: '300px'
    },
    pointsWrapper: {
        display: 'inline-flex',
        paddingRight: 10,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
          },
    },
    circle: {
        paddingRight: 10,
        [theme.breakpoints.down('sm')]: {
            padding: 5
        },
    },
    pieCircle: {
        position: 'relative',
        marginRight: 10,
        width: 60,
        height: 60,
        [theme.breakpoints.down('sm')]: {
            margin: 5,
            width: 45,
            height: 45,
        },
    },
    pieText: {
        position: 'absolute',
        top: 0,
        left: 0,
        // transform: "translate(-50%, -50%)"
    },
    arrowHover: {
        "&:hover": {
            cursor: 'pointer',
            // opacity: "0.7",
            backgroundColor: theme.palette.neutral.light,
        }
    },
    description: {
        maxWidth: '50%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '80%'
          },
        paddingTop: 10,
        paddingBottom: 10
    },
    checkIn: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '80%'
          },
        //paddingTop: 10,
        paddingBottom: 10
    },
    checkInTop: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '80%'
          },
        paddingTop: 20,
    },
    checkInBottom: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '80%'
          },
        paddingTop: 10,
        paddingBottom: 20,
    },
    button: {
        width: 175,
        borderRadius: 0,
        margin: 5,
        height: 40,
        cursor: 'pointer',
    },
    dialogBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '300px',
        height: '200px',
        borderColor: 'secondary'
    },
    mapView: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            alignItems: 'left',
        },

    },
    optionsContainer: {
        width: '50%',
    },
    website: {
        alignSelf: 'left',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            left: 0,
            justifyContent: 'left',
            alignItems: 'left',
        },

    }
  }));

export const styles = {
    title: {
        fontFamily: 'Poppins',
        textTransform: 'uppercase',
        textAlign: 'center',
        maxWidth: "80vw",
        // paddingTop: "10px"
    },
    title2: {
        fontFamily: 'Poppins',
        textTransform: 'capitalize',
        textAlign: 'center',
        maxWidth: "80vw",
    },
    viewButton: {
        fontFamily: 'Poppins',
        textTransform: 'uppercase',
        textAlign: 'center',
        "&:hover": {
            cursor: 'pointer',
            opacity: "0.7"
        },
    },
    date: {
        fontFamily: 'Poppins',
        textTransform: 'uppercase',
        textAlign: 'left',
        fontWeight: 600,
    },
    body: {
        fontFamily: 'Poppins',
        textTransform: 'uppercase',
        textAlign: 'left',
    },
    addressContainer: {
        position: 'absolute',
        left: 20,
        bottom: 15,
        backgroundColor: 'grey',
        opacity: .5,
        maxWidth: "50%",
        paddingLeft: 10,
        paddingRight: 10,
    },
    addressTitle: {
        color: '#fff',
        fontFamily: 'Poppins',
        textTransform: 'uppercase',
        textAlign: 'left',
    },
    address: {
        color: '#fff',
        fontFamily: 'Poppins',
        textTransform: 'uppercase',
        textAlign: 'left',

    },
    btnDirection: {
        color: '#fff',
        fontFamily: 'Poppins',
        textTransform: 'uppercase',
        textAlign: 'left',
    }
}