import { formatDate } from '@functions';
import { useWindowDimensions } from '@hooks';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from "./styles";

export default function EventCarouselCard({event, isSoonestEvent}) {
    const { height, width } = useWindowDimensions();

    const theme = useTheme();
    const showPhone = useMediaQuery(theme.breakpoints.down('sm'));

    if(event) {
        return (
            <div style={styles.cardContainer}>
                <div style={showPhone ? { marginBottom: height / 200, overflow: 'hidden',display: "flex", justifyContent: "center", alignItems: "center",border: "1px solid lightgray"} : { marginBottom: height / 20, width: width / 2.7, display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid lightgray"}}>
                    <img style={styles.eventImage} src={event.image} alt="fireSpot"/>
                </div>
                <p style={isSoonestEvent ? styles.swiperTextBoldPrimary : styles.swiperTextBoldSecondary}>{formatDate(event.dateString)}</p>
                <p style={isSoonestEvent ? styles.swiperTextPrimary : styles.swiperTextSecondary}>{event.name}</p>
                <p style={isSoonestEvent ? styles.swiperTextPrimary : styles.swiperTextSecondary}>{event.school}</p>
                <p style={isSoonestEvent ? styles.swiperTextBoldPrimary : styles.swiperTextBoldSecondary}>{`${event.reward}PTS`}</p>
            </div>
        )
    } else {
        return (
            <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
        )
    }

}
