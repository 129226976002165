import { PRIMARY } from "@constraints";
import { Context } from '@context';
import translate from "@translate";
import { useContext } from 'react';
// import { useNavigate } from "react-router-dom";
import EventsGrid from '../events-grid';

export default function EventsListDesktop({size}) {
    const { futureEventsFiltered } = useContext(Context);
    // const navigate = useNavigate();
    //console.log("futureEventsFiltered", futureEventsFiltered)
    return (
        <div style={{width: "95%"}}>
            <p style={{paddingLeft: "2%", textTransform: "uppercase", color: PRIMARY, fontWeight: "bold", marginBottom: 25}}>{translate("upcomingEvents")}</p>
            <EventsGrid events={futureEventsFiltered}/>
        </div>
    )
}
