import EventListDesktop from '@components/event-list/layouts/EventListDesktop';
import EventListMobile from '@components/event-list/layouts/EventListMobile';
import { Context } from '@context';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import { useContext } from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    // [theme.breakpoints.down('sm')]: {
    //   backgroundColor: theme.palette.primary.main,
    // },
  },
}));

export const EventList = () => {
  const classes = useStyles();
  const theme = useTheme();
  const showMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const showDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const { getData, students } = useContext(Context);

  // useEffect(() => {
  //   getData()
  // },[])
  
  return (
    <div className={classes.container}>
      {/* <p>{showMobile ? "mobile" : "desktop"}</p> */}
      {showMobile ? <EventListMobile size="sm"/> : showDesktop ? <EventListDesktop/> : <EventListMobile size="md"/>}
    </div>
  );
};