import { Context } from "@context";
import { getRewardStatus } from "@functions";
import { Box, Grid } from '@mui/material';
import { useContext } from 'react';
import RewardCard from './reward-card';

export default function RewardsGrid({size="lg"}) {
  const { parent, rewards, redemptions } = useContext(Context);
  //console.log( rewards);
  let pointsAvailale = parseInt(parent?.totalCredits) - parseInt(parent?.creditsRedeemed)
  const styles = {
      box: {
          paddingLeft: size === "lg" ? "2%" : "6%",
          paddingRight: size === "lg" ? 0 : "6%" 
      }
  };

  return (
    <Box sx={{ flexGrow: 1 }} style = { styles.box }>
      <Grid container spacing={{ xs: 2, md: 3.5 }} columns={{ xs: 1, sm: 2, md: 2, lg:3 }}>
        {rewards && rewards.map((reward, index) => {
          if (parseInt(reward.quantityLeft) > 0) {
            return (<Grid item xs={1} sm={1} md={1} key={index}>
              <RewardCard redemptions={redemptions} reward={reward} size={size} status={getRewardStatus(reward, pointsAvailale)} />
            </Grid>)
          }
        })}
      </Grid>
    </Box>
  );
}
