
import { LoginForm } from '../../components/login';
import { Typography, Link } from '@mui/material';
import mainLogo from '../../res/logo.png';
import styles from './styles';
import { Context } from '@context';
import React, { useContext} from 'react';
import {Button} from '@mui/material';
import translate from '../../i18n/translate';
import { makeStyles } from "@mui/styles";
import Campus from '../../components/campuses';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%'
  },
  description: {
    display: "flex", 
    justifyContent: "center",
    textAlign: 'center',
    fontSize: 12,
    paddingLeft: 20, 
    paddingRight: 20,
    marginBottom: 15,
  },
  textContainer: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    padding: 20,
    width: 350,
  }

}));
export const Login = () => {
  const classes = useStyles();
  const {setLocale, campuses } = useContext(
    Context
  );

  //console.log('campuses on logi', campuses);

  return (
    <div style={styles.mainContainer}>
      <Button onClick={() => setLocale('en-us')}>{translate('english')}</Button>
      <Button onClick={() => setLocale('es-es')}>{translate('spanish')}</Button>
      <img style={styles.logo} src={mainLogo} alt="fireSpot"/>
      <div style={styles.formContainer}>
        <LoginForm/>
      </div>
      
      <div className={classes.textContainer}>
        <Typography 
          variant="h12" 
          gutterBottom 
          component="div" 
          color="secondary" 
          style={styles.description}
        >
          {translate('supportTextLine1')} {/*Your ParentU email address ....*/}
          </Typography>
          <Typography 
          variant="h12" 
          gutterBottom 
          component="div" 
          color="secondary" 
          style={styles.description}
        >    
          <Link style={{width: 110, textAlign: 'center', marginRight: 5}} href="https://teams.lubbockisd.org/selfserve/EntryPointHomeAction.do?parent=true">{translate('clickHere')}</Link>
          {translate('supportTextLine2')}
          </Typography>
          <Typography 
          variant="h12" 
          gutterBottom 
          component="div" 
          color="secondary" 
          style={styles.description}
        >
          {translate('supportTextLine3')}
          </Typography>
      </div> 
      <div className={[classes.container, {justifyContent: 'center', alignItems: 'center'}]}>
      {campuses.length > 0 && campuses.map((item,index) => <Campus item={item} key={index}/>)}
      </div>
      <p>Version 1.5</p>
    </div>
  );
};
