import { PRIMARY, SECONDARY } from '@constraints';

export default {
    swiperContainer: {
        width: "100%",
        paddingLeft: "3%",
        height: "30vh",
        marginBottom: '8vh'
    },
    swiperHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    swiperTitlePrimary: {
        fontWeight: "bold",
        fontSize: 17,
        color: PRIMARY,
        marginTop: 8,
        marginBottom: 8,
    },
    swiperTitleSecondary: {
        fontWeight: "bold",
        fontSize: 17,
        color: SECONDARY,
        marginTop: 8,
        marginBottom: 8,
    },
    swiperButton: {
        width: 200,
        height: 20,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: "4%",
    },
    swiperButtonSecondary: {
        width: 200,
        height: 20,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: "4%",
        color: SECONDARY,
    },
    rewardBox: {
        display: "flex",
        justifyContent: "center", 
        alignItems: "center",
        padding: 15,
        marginBottom: 5,
        border: "1px solid lightgray"
    },
    rewardBoxLocked: {
        display: "flex",
        justifyContent: "center", 
        alignItems: "center",
        padding: 15,
        marginBottom: 5,
        width: "34vw",
        border: "3px solid lightgray"
    },
    slideContainer: {
        backgroundColor: "#fff",
        width: '40vw',
        height: '16vh',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: 0.5,
    },
    cardContainer: {
        
        // borderWidth: 1,
        // borderRadius: 2,
        // backgroundColor: "#fff",
        // width: 150,
        // height: 150,
        // boxShadow: "0.5px 0px 1.5px 1px lightgray",
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        // justifyContent: "flex-start"
    },
    iconContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "35%",
        paddingTop: 50,
        paddingBottom: 50,
    },
    appleIcon: {
        width: "auto",
        height: "auto",
        maxWidth: "95%",
        position: "relative",
        top: 20
    },
    googleIcon: {
        width: "auto",
        height: "auto",
        maxWidth: "95%",
        position: "relative",
        bottom: 10
    },
    titleLinks: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        color: SECONDARY
    },
    textLinks: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 10,
        color: SECONDARY,
        fontSize: 15
    },
    titleEvents: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        height: "1%",
        color: PRIMARY,
        fontSize: 20
    },
    titleRewards: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        color: SECONDARY
    },
    viewButtonContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 100,
    },
    redeemButtonContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
    },
    redeemButton: {
        width: "100%",
        height: 45,
        maxWidth: "100%",
    },
    rewardContainer: {
        width: "90%",
        maxWidth: "95%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
    },
    rewardImage: {
        width: "auto",
        height: "100%",
        maxWidth: "100%",
    },
    rewardInfoContainer: {
        display: "flx",
        flexDirection: "column",
        alignItems: "flex-start",
        height: 20,
    },
    rewardText: {
        color: PRIMARY,
        fontSize: 14,
        lineHeight: 0.5,
        margin: 1
    },
    rewardTextBold: {
        color: PRIMARY,
        fontWeight: "bold",
        fontSize: 14,
        lineHeight: 0.5,
        marginRight: 4
    },
    eventContentContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "75%",
        width: "90%",
        padding:0,
    },
    eventImage: {
        height: 120,
        width: "auto%",
        aspectRatio: 1,
        objectFit: "cover",
        resizeMode: "contain"
    },
    eventInfoContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
    },
    eventDate: {
        lineHeight: 0.5,
        fontWeight: "bold",
        color: PRIMARY,
        fontSize: 16
    },
    eventName: {
        lineHeight: 0.5,
        fontSize: 16,
        color: SECONDARY,
    },
    eventSchool: {
        lineHeight: 0.5,
        color: SECONDARY,
        fontSize: 14,
    },
    eventTime: {
        lineHeight: 0.5,
        color: SECONDARY,
        fontSize: 14
    },
    creditsCircleContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    creditText: {
        marginTop: 5, 
        fontSize: 14,
        color: SECONDARY
    },
    dotRow: {
        display: "flex",
        justifyContent: "space-around"
    },
    swiperTextSecondary: {
        color: SECONDARY,
        margin: 0,
        display: '-webkit-box',          // Creates a block element box
        WebkitBoxOrient: 'vertical',     // Orients the box vertically
        WebkitLineClamp: 5,              // Limits the text to 3 lines
        overflow: 'hidden'               // Hides the overflow text
    },
    swiperTextBoldSecondary: {
        fontWeight: "bold",
        color: SECONDARY,
        margin: 0,
    },
    swiperTextPrimary: {
        color: PRIMARY,
        margin: 0,
    },
    swiperTextBoldPrimary: {
        fontWeight: "bold",
        color: PRIMARY,
        margin: 0,
    },
}