import React, {useEffect, useState} from 'react';
import { db } from "@database";
import { collection, getDocs, query, where } from "firebase/firestore";
import Announcement from '../../components/announcements';
import { makeStyles } from "@mui/styles";
import { Context } from '@context';
import { useContext } from 'react';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%'
  }
});

export const Announcements = () => {
  const [data, setData] = useState([]);
  const classes = useStyles();
  const { students, filterValues } = useContext(Context);
  const unique = [...new Set(students.map(item => item.studentSchool))];
  unique.push('Select All');
  const fetchAnnouncement = async() => {
    try{
      const schoolStates = filterValues.schools;
      const schoolKeys = schoolStates && Object.keys(schoolStates);
      const schoolNames = schoolKeys && schoolKeys.filter((key) => {
        return schoolStates[key]
      })
      //console.log('called res', schoolNames);
      schoolNames.push('Select All');
      const colRef = collection(db, 'announcements');
      const q = query(colRef, where('school', 'in', schoolNames));
      const colSnap = await getDocs(q);
      const tempData = []
      colSnap.docs.forEach((doc, idx) => { 
        // doc.data().date = doc.data().date.toDate().toDateString();
        tempData.push(doc.data());
        tempData[idx].id = doc.id;
      })
      // console.log('tempData', tempData);
      setData(tempData);
    } catch(e) {
      console.log(e);
    }

  }

  useEffect(() => {
      fetchAnnouncement();
  },[filterValues])
   
  return (
    <div className={classes.container}>
      {data.length > 0 && data.map(item => <Announcement item={item} key={item.id}/>)}
    </div>
  );
}