import { db } from "@database";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";

export const subscribeNotes = (parentId, setNotes) => {
  try {
    const notesRef = query(collection(db, `parents/${parentId}/notes`), orderBy("date", "desc"))
    onSnapshot(notesRef, (querySnapshot) => {
        if (querySnapshot.docs.length > 0){
          const notes = [];
          querySnapshot.docs.forEach((note, idx) => {
            // console.log("NOTE", idx)
              notes.push(note.data());
              notes[idx].id = note.id;
              notes[idx].dateString = note.data().date.toDate().toDateString();
          });
          // console.log("NOTES", notes);
          setNotes(notes);
        }
      })
  } catch (error) {
    console.log("ERROR SUBSCRIBING TO NOTES COLLECTION", error);
  }
}