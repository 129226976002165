import { Context } from "@context";
import CircleIcon from '@mui/icons-material/Circle';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import translate from '@translate';
import React, { useContext, useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
    studentForm: {
        width: '100%',
        height: 200,
    },
    uncheckedBox: {
        width: 20,
        height: 20,
        minWidth: 20,
        backgroundColor: "#fff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        "&:hover": {
            cursor: 'pointer',
        }
    },
    groupContainer: {
        width: '100%',
        paddingBottom: 25,
    },
    studentRow: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
        padding: "10px 0 10px 10px"
    },
    name: {
        fontFamily: "Poppins",
        color: "#fff",
        fontWeight: 200,
        textAlign: "left",
        textTransform: "capitalize",
    }
}));

const styles = {
    text: {
        color: "#fff",
        fontFamily: 'Poppins',
        paddingRight: 5,
    }
}

export const StudentsFilter = ({filterType}) => {
    const [schoolNames, setSchoolNames] = useState(null);
    const [categoryNames, setCategoryNames] = useState(null);

    const { allStudents, allEvents, filterValues, setFilterValues } = useContext(Context);

    const classes = useStyles();

    const handleValueChange = (name, type) => {
        type === 'students' && setFilterValues(((prevState) => ({ ...prevState, students: { ...filterValues.students, [name]: !filterValues.students[name]}})));
        type === 'schools' && setFilterValues(((prevState) => ({ ...prevState, schools: { ...filterValues.schools, [name]: !filterValues.schools[name]}})));
        type === 'categories' && setFilterValues(((prevState) => ({ ...prevState, categories: { ...filterValues.categories, [name]: !filterValues.categories[name]}})));
    };

    const UncheckedBox = ({name}) => {
            return (
                <div className={classes.uncheckedBox} onClick={() => handleValueChange(name, filterType)} >
                </div>
            )
    }
    const CheckedBox = ({name}) => {
        return (
            <div className={classes.uncheckedBox} onClick={() => handleValueChange(name, filterType)}>
                <CircleIcon sx={{fontSize: 12}} color="secondary" />
            </div>
        )
    }

    const FilterList = () => {
        return (
            <>
                {filterValues.students && filterType === 'students' && 
                allStudents.map((student, index) => {
                        const name = `${student.studentFirstName}_${student.studentLastName}`;
                        return (
                            <div 
                                className={classes.studentRow}
                                key={index}
                                >
                                <Typography variant="body1" className={classes.name} sx={styles.text}>
                                    {`${student.studentFirstName} ${student.studentLastName}`}
                                </Typography>
                                {filterValues.students[name] ? <CheckedBox name={name} /> : <UncheckedBox name={name} />}
                            </div>
                        )
                    })
                }
                {filterValues.schools && filterType === 'schools' && schoolNames &&
                    schoolNames.map((school, index) => {
                        return (
                            <div 
                                className={classes.studentRow}
                                key={index}>
                                <Typography variant="body1" className={classes.name} sx={styles.text}>
                                    {school}
                                </Typography>
                                {filterValues.schools[school] ? <CheckedBox name={school} /> : <UncheckedBox name={school} />}
                            </div>
                        )
                    })
                }
                {filterValues.categories && filterType === 'categories' && categoryNames &&
                    categoryNames.map((category, index) => {
                        return (
                            <div 
                                className={classes.studentRow}
                                key={index}>
                                <Typography variant="body1" className={classes.name} sx={styles.text}>
                                    {translate(category)}
                                </Typography>
                                {filterValues.categories[category] ? <CheckedBox name={category} /> : <UncheckedBox name={category} />}
                            </div>
                        )
                    })
                }
            </>
        )
    }

    useEffect(() => {
        if(!schoolNames && allStudents) { 
            const uniqueSchools = Object.keys(filterValues?.schools || {});
            setSchoolNames(uniqueSchools)
        }  
        if(!categoryNames && allEvents && filterValues.categories) { 
            // console.log("FILTER VALUES", filterValues)
            const uniqueCategories = Object.keys(filterValues?.categories || {});
            setCategoryNames(uniqueCategories)
        }  
    }, [filterValues, allStudents, allEvents, schoolNames, categoryNames]);

    return (
        <div className={classes.groupContainer}>
            <FilterList />
        </div>
    );
}   