import { FormattedMessage } from "react-intl";
import React, { useContext } from 'react';
import ENGLISH from './messages/en-US';
import SPANISH from './messages/es-ES';
import { Context } from "@context";


const translate = (id) => <FormattedMessage id={id} />
export default translate;


export const useTranslate = (id) => {
    const { locale } = useContext(Context);
  
    if(locale && locale === 'es-es') {
      return SPANISH[locale][id]
    } else if(locale) {
      return ENGLISH[locale][id]
    } else {
      // console.log("NO LOCALE AVAILABLE")
      return ""
    }
  }
