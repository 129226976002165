import { db } from "@database";
import { collection, onSnapshot } from "firebase/firestore";

export const subscribeRedemptions = (parentId, setRedemptions) => {
  try {
    const redemptionsRef = collection(db, `parents/${parentId}/redemptions`)
    onSnapshot(redemptionsRef, (snapshot) => {
        if(snapshot.docs.length > 0){
          const redemptions = [];
          snapshot.docs.forEach((credit, idx) => {
            const data = credit.data();
            redemptions.push(credit.data());
            //redemptions[idx].id = credit.idx
                // to spread the nest array of items
            const mappedItems = data.redemption ? data.redemption.forEach((item,idy) => {
              redemptions.push(item);
             // redemptions[idx].id = new Date
            }) : [];
            redemptions.forEach((item,idy) => {
              redemptions[idy].id = idy;
            });
          })
    
      
           //console.log("REDEMPS", redemptions);
          setRedemptions(redemptions);
        }
        if(snapshot.docs.redemption){
          console.log("snapshot.docs.redemption",snapshot.docs.redemption)
        }
      })
  } catch (error) {
    console.log("ERROR SUBSCRIBING TO REDEMPTIONS COLLECTION", error);
  }
};