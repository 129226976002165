import { db } from "@database";
import { getSchoolsArray } from "@functions";
import { collection, onSnapshot, query, where } from "firebase/firestore";

export const subscribeRewards = (studentsArray, setRewards) => {
  try {
    const schools = getSchoolsArray(studentsArray);
    const rewardsRef = collection(db, `rewards`);
    const rewardsQuery = query(rewardsRef, where("school", "in", schools));
    onSnapshot(rewardsQuery, (snapshot) => {
      if(snapshot.docs.length > 0){
        const rewardsArray = [];
        snapshot.docs.forEach((event, idx) => {
          rewardsArray.push(event.data())
          rewardsArray[idx].id = event.id;
        })
        setRewards(rewardsArray);
      }
    });
  } catch (error) {
    console.log("ERROR SUBSCRIBING TO REWARDS COLLECTION", error);
  }
};

// const getAllRewards = async (studentsArray) => {

//   const schools = getSchoolsArray(studentsArray);
//   //schools.push("")
//   console.log("SCHOOLS", schools)
//   const colRef = collection(db, "rewards");
//   const q = query(colRef, where("school", "in", schools));
//   const querySnapshot = await getDocs(q);
//   const rewardsArray = []

//   try {
//       let colorIndex = 0;
//       querySnapshot.docs.forEach((doc, idx) => {
//         console.log("REWARD DOC", doc.data())
//         rewardsArray.push(doc.data())
//         rewardsArray[idx].id = doc.id;
//       })
//       console.log("rewards array", rewardsArray)
//       // setRewards(rewardsArray)
//       return rewardsArray;
//   } catch (error) {
//       console.log("error", error)
//   }
// }