import { LOCALES } from "../locales";

export default {
  [LOCALES.SPANISH]:{
    'login': 'ACCESO',
    'singUp': 'INSCRIBIRSE',
    'logout': 'Cerrar sesión',
    'menu': "MENÚ",
    'filters': 'filtros',
    'resetFilters': 'restablecer filtros',
    'events': "eventos",
    'allEvents': 'todos los eventos',
    'achievedEvents': 'eventos logrados',
    'archivedevents': 'eventos archivados',
    'addNewEvent': 'agregar evento',
    'searchEvents': 'buscar eventos',
    'eventName': 'Nombre del Evento',
    'uploadCSV': 'subir csv',
    'schoolName': 'nombre de escuela',
    'upcomingEvents': 'próximos eventos',
    'download-card-title': "DESCARGAR LA LISD APLICACIÓN",
    'download-card-paragraph': "Descargue la aplicación oficial de Lubbock ISD: su fuente oficial de noticias e información oportunas. ¡Juntos, con su aplicación LISD Parent University, tendrá toda la información que necesita al alcance de su mano!",
    "rewards": "recompensas",
    "view": "VER",
    "view rewards": "VER RECOMPENSAS",
    "view events": "VER EVENTOS",
    "VIEW CALENDAR": "VER CALENDARIO",
    "redeem now": "canjear ahora",
    "redeem": "redimir",
    "credits": "creditos",
    "dashboard": "tablero de mandos",
    "events": "eventos",
    "calendar": "calendario",
    "parents": "padres",
    "resources": "recursos",
    "options": "opciones",
    "notes": "notas",
    'addANote': 'agregar nota',
    "account": "cuenta",
    "gradebook": "libro de calificaciones",
    "students": "estudiantes",
    "credits": "creditos",
    "unlocked": "desbloqueada",
    "locked": "bloqueada",
    "start time": "hora de inicio",
    "end time": "para terminar",
    "total available credits": "creditos disponibles",
    'totalCreditsRedeemed': 'créditos canjeados',
    "VIEW CREDIT HISTORY": "ver historial de creditos",
    "CHECK IN": "registrarse",
    "REDEEM POINTS": "canjear puntos",
    'email': 'correo electronico',
    'password': 'contraseña',
    'signIn': 'iniciar sesión',
    'accountDescription': "no tiene una cuenta, crear aqui",
    'changeLanguage': 'cambiar idioma',
    'dataUpdateMidnight': 'los datos se actualizan todos los días a medianoche',
    'schools': 'escuelas',
    'eventCategories': 'categoria de eventos',
    'eategories': 'categorias',
    'academic': 'academia',
    'athletic': 'atletismo',
    'gradeLevel': 'nivel de grado',
    'allCampus': 'todo el campus',
    'resetFilters': 'restablecer filtros',
    'aboutToUse': 'está a punto de usar',
    'forThisItem': 'por este artículo',
    'eventList': 'lista de eventos',
    'january': 'enero',
    'february': 'febrero',
    'march': 'marzo',
    'april': 'abril',
    'may': 'mayo',
    'june': 'junio',
    'july': 'julio',
    'august': 'agosto',
    'september': 'septiembre',
    'october': 'octubre',
    'november': 'noviembre',
    'december': 'diciembre',
    'today': 'hoy',
    'resetFilters': 'restablecer filtros',
    'selectDate': 'seleccione la fecha para los detalles del evento',
    'monday': 'lunes',
    'mMonday': 'L',
    'tuesday': 'martes',
    'tTuesday': 'M',
    'wednesday': 'miercoles',
    'wWednesday': 'M',
    'thursday': 'jueves',
    'tThursday': 'J',
    'friday': 'viernes',
    'fFriday': 'V',
    'saturday': 'sabado',
    'sSaturday': 'S',
    'sunday': 'domingo',
    'sSunday': 'D',
    'noteSubject': 'asunto de la nota',
    'Event Details': 'detalles del evento',
    'onlineEvent': 'evento en línea',
    'inPersonEvent': 'evento en persona',
    'thankYouForCheckIn': 'gracias por registrarse',
    'english': 'Ingles',
    'spanish': 'Español',
    'manageEvents': 'administrar eventos',
    'manageRewards': 'administrar recompensas',
    'manageResources': 'administrar recursos',
    'eventDate': 'Fecha del Evento',
    'addReward': 'agregar recompensa',
    'nameOfReward': 'nombre de la recompensa',
    'auantity': 'cantidad',
    'pointsToRedeem': 'creditos para recompensar',
    'description': 'Descripción',
    'urlOfThePhoto': 'enlace de la foto',
    'save': 'guardar',
    'addNewResource': 'Add new resource',
    'resourceName': 'nombre del recurso',
    'resourceLink': 'enlace de recursos',
    'parentCreditHistory': 'historial de creditos',
    'totalParents': 'total de padres',
    'filtersBy': 'filtros por',
    'parentName': 'nombre de pariente',
    'totalCredits': 'total de creditos',
    'viewHistory': 'ver historial',
    'backToParents': 'regresar',
    'eventRequestCredit': '¿no ves un evento? pida credito',
    'requestCredit': "pida credito",
    'edit': 'editar',
    'delete': 'borrar',
    'creditsToRedeem': 'créditos para redimir',
    'saveChanges': 'guardar',
    'deleteEvent': 'borrar',
    'uploadImage': 'cargar imagen',
    'eventDescription': 'Descripción',
    'eventStart': 'inicio del evento',
    'eventEnd': 'para terminar',
    'eventLatitude': 'latitud',
    'eventLongitude': 'longitud',
    'eventCategory': 'categoria',
    'points': 'creditos',
    'aboutToDelete': 'esta a punto de eliminar',
    'actionUndone': 'esta acción no se puede deshacer',
    'likeToContinue': '¿le gustaria continuar?',
    'yes': 'si',
    'no': 'no',
    'downloadApp': 'descargue la app LISD',
    'noEvents': 'no hay eventos en esta fecha',
    'TOTAL AVAILABLE CREDITS': 'CRÉDITOS TOTALES DISPONIBLES',
    'REDEEM CREDITS': 'CANJEAR CRÉDITOS',
    'REDEEM': "CANJEAR",
    "Event Details": "Detalles del Evento",
    "Get Directions": "Get Direcciones",
    "welcome": "bienvenidos",
    "calendarDescription": "El Calendario es su fuente de eventos escolares importantes con fechas específicas para su estudiante(s). Haga clic en la fecha para ver una lista de eventos, codificados por color por estudiante para su conveniencia.",
    "redeemedReward": "¡Has canjeado una recompensa a través de Parent University!",
    "checkEmail": "Revise su correo electrónico para obtener su recibo de canje de recompensas.",
    "pickUpReward": "Su recompensa estará disponible para recogerla en 7 días hábiles en la oficina de la escuela.",
    "mustHaveReciept": "Debe tener su recibo de redención de recompensa para reclamar su recompensa.",
    'forgotpassword': 'Has olvidado tu contraseña',
    'resetpassword': 'Restablecer la contraseña',
    'resetdescription': 'Ingrese su dirección de correo electrónico y le enviaremos un enlace para restablecer su contraseña.',
    'emailaddress': 'Dirección de correo electrónico',
    'submit': 'Enviar',
    'returntologin': 'Volver a iniciar sesión',
    'changepassword': 'Contraseña Español',
    'announcements': 'anuncios',
    'supportTextLine1': 'Su dirección de correo electrónico de ParentU es la misma dirección de correo electrónico que utiliza para el Libro de calificaciones.',
    'supportTextLine2': 'para obtener ayuda con el libro de calificaciones o comuníquese con la oficina principal de su campus.',
    'supportTextLine3': 'Haga clic en el enlace Olvidé mi contraseña de arriba para establecer o restablecer su contraseña para Parent U.',
    'clickHere': 'Haga clic aquí',
    'Campus': 'Campus',
    'WEBSITE': 'SITIO WEB',
    "Address" : "Dirección",
    "descriptionRequired" : 'Se requiere descripción',
    'required' : 'Requiere',
    'Credit History': 'Historial de crédito',
    'General': 'General',
    'Credits are required': 'Se requieren créditos',
    "ViewRequest":"Ver solicitud",
    "Submit Request" : "Enviar peticion",
    "Academic": "Académico",
    "View More": "Ver más",
    "Redeemed" : "Redimido",
    "Cancel" : "Cancelar",
    "Community": "Comunidad",
    "Fine Arts" : "Bellas Artes",
    "Removing...": "Eliminando...",
    "Remove" : "Eliminar",
    "Are you sure you want to remove this note?": "¿Estás seguro de que deseas eliminar esta nota?",
    "Remove Note": "Eliminar nota",
    "Add a Note": "Agrega una nota",
    "Subject": "Sujeto",
    "Available Credits": "Créditos Disponibles",
    "Redeemed Credits": "Créditos Canjeados",
    "Date": "Decha",
    "Name": "Nombre",
    "VIEW CREDITS": "VER CRÉDITOS",
    "District": "Distrito",
    "Redeem Again": "Canjear de Nuevo",
    "Sports": "Deportes",
    "descriptionTitle": "Descripción: ",
    "Lock": "Cerrar",
    "Credits": "Créditos"


  }
}