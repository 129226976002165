import { useState } from 'react'

export default function TouchableOpacity({children, color="lightgray", onClick=null}) {
    const [touched, setTouched] = useState(false)

    const styles = {
        buttonTouched: {
            background: color,
            cursor: "pointer",
            opacity: 0.5,
            transition: "opacity 300ms ease",
        },
        buttonUntouched: {
            cursor: "pointer",
            opacity: 1,
            transition: "opacity 300ms ease",
        }
    }


    const toggleTouched = () => {
        setTouched(true)
        // console.log("clicked")
        onClick && onClick()
    }
      
    const handleMouseUp = () => {
        // Handle smooth animation when clicking without holding
        setTimeout( () => {
          setTouched(false);
        }, 150);
    }

    return (
        <div 
          style={touched ? styles.buttonTouched : styles.buttonUntouched}
          onMouseDown={toggleTouched}
          onMouseUp={handleMouseUp}
        >{children}</div>
    )
}
