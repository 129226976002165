export default {
    cardsContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: 15,
        marginBottom: 15,
        paddingLeft: "3%"
    },
    button: {
        width: 175,
        borderRadius: 0,
        margin: 5,
        height: 40
    },
}