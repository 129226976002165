import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from "@mui/styles";
import React from 'react';
import { Route, Routes } from "react-router-dom";
import { Header } from "../../components/header";
import { DesktopSideNav } from "../../components/navigation/desktopSideNav";
import { Account } from '../../routes/account';
import { Calendar } from '../../routes/calendar';
import { Credits } from '../../routes/credits';
import { Dashboard } from '../../routes/dashboard';
import { EventDetails } from '../../routes/event-details';
import { EventList } from '../../routes/event-list';
import { Notes } from '../../routes/notes';
import { Resources } from '../../routes/resources';
import { Announcements } from '../../routes/announcements';
import { CreditHistory } from '../credit-history';


const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  drawerPaper: {
    minHeight: "100vh",
    width: 300,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "6px 0 4px -4px rgba(79,79,79,0.3)",
    overflow: "hidden",
  },
});


export const Home = () => {
  const classes = useStyles();
  const theme = useTheme();

  const showMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className={classes.container}>
      {!showMobile && <div>
        <DesktopSideNav />
      </div>}
      <div style={{ width: showMobile ? '100%' : `calc(100% - ${'20vw'})`, paddingLeft: showMobile ? 0 : '20vw' }}>
      <Header />
        <Routes>
          <Route path='/dashboard/*' element={<Dashboard />} />
          <Route path='/account' element={<Account />} />
          <Route path='/notes' element={<Notes />} />
          <Route path='/calendar' element={<Calendar />} />
          <Route path='/resources' element={<Resources />} />
          <Route path='/announcements' element={<Announcements />} />
          <Route path='/credits' element={<Credits />} />
          <Route path='/event-list' element={<EventList />} />
          <Route path='/event-details/:id/*' element={<EventDetails />} /> 
          <Route path='/credit-history' element={<CreditHistory />} />
        </Routes>
      </div>
    </div>
  );
};
