import { addEventsToCalendar, filterEvents, orderEventsByDate, subscribeUserLocation } from "@functions";
import { onAuthStateChanged } from "firebase/auth";
import { createContext, useEffect,useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { auth } from "../firebase";
import { LOCALES } from "../i18n";
import { getParentId, getStudentData, subscribeCreditHistory, subscribeEvents, subscribeNotes, subscribeParentData, subscribeRedemptions, subscribeRewards, subscribeStudents, getCampusesData, loginReward } from "../services/firebase";
import { loginForm } from "../components/login";

export const Context = createContext();


export const ContextProvider = ({ children }) => {
  let arrayEvents;
  const [setTrue, allSetTrue] = useState(true)
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["language"]);
  const [signInEmail, setSignInEmail] = useState("");
  const [parent, setParent] = useState(null);
  const [redemptions, setRedemptions] = useState([]);
  const [creditHistory, setCreditHistory] = useState([]);
  const [notes, setNotes] = useState([]);
  const [students, setStudents] = useState([]);
  const [allStudents, setAllStudents] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [allEventsFiltered, setAllEventsFiltered] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);
  const [futureEventsFiltered, setFutureEventsFiltered] = useState([]);
  const [mark, setMark] = useState([]);
  const [campuses, setCampuses] = useState([]);
  const [locale, setLocale] = useState(cookies.language ? cookies.language : LOCALES.ENGLISH);
  const [language, setLanguage] = useState(cookies.language ? cookies.language : 'en-us');
  const [userLocation, setUserLocation] = useState({
    coords: {
      latitude: "",
      longitude: "",
    }
  });
  const [showRewards, setShowRewards] = useState(false);

  const [filterValues, setFilterValues] = useState({
    students: null,
    schools: null,
    categories: null
  });
 // console.log('filterValues', filterValues);

  const onRedeem = (reward) => {
    // console.log("REDEEMED!", reward);
  };

  const isAllEventsSet = useRef(false);
  const [firstLogin, setFirstLogin] = useState(false);
  const [extraReward, setExtraReward] = useState(false);

  const navList = [
    { name: "dashboard", link: "/home/dashboard" },
    { name: "announcements", link: "/home/announcements" },
    { name: "events", link: "/home/event-list" },
    { name: "credits", link: "/home/credits" },
    { name: "resources", link: "/home/resources" },
    { name: "calendar", link: "/home/calendar" },
    { name: "notes", link: "/home/notes" },
  ];

  const titles = [
    { name: "Event Details", link: "/home/event-details/id=:id" },
    { name: "Credit History", link: "/home/credit-history" },
  ];

  const filterData = async (type) => {
    switch (type) {
      case 'students':
        const studentData = await filterEvents(null, allStudents, filterValues, type);
        setStudents(studentData);
        break;
      case 'schools':
        setStudents(filterEvents(null, allStudents, filterValues, type));
        break;
      case 'categories':
      //  console.log('test events');
        const eventData = await filterEvents(allEvents, allStudents, filterValues, type);
        const futureEventData = await filterEvents(futureEvents, allStudents, filterValues, type);
        setAllEventsFiltered(eventData);
        setFutureEventsFiltered(orderEventsByDate(futureEventData));
        break;
      default:
        break;
    }
  };

  const getData = async () => {
    // console.log("INITIALIZING DATA");
    setLoading(true);
    try {
      const parentId = await getParentId();
      const studentsData = await getStudentData(parentId);
      // setAllStudents(studentsData);
      subscribeStudents(parentId, setAllStudents);
      subscribeEvents(arrayEvents,setTrue, allSetTrue, studentsData, setAllEvents, setAllEventsFiltered, setFutureEvents, setFutureEventsFiltered);
      subscribeParentData(parentId, setParent);
      subscribeRewards(studentsData, setRewards);
      subscribeRedemptions(parentId, setRedemptions);
      subscribeCreditHistory(parentId, setCreditHistory, loadStudents);
      subscribeNotes(parentId, setNotes);
      subscribeUserLocation(setUserLocation);
      //loginReward(parentId,firstLogin, setFirstLogin, extraReward, setExtraReward,setLoading)
    } catch (error) {
      setError(error); // Set the error state
    } finally {
      setLoading(false);
    }
  };

  const loadStudents = async () => {
    setLoading(true);
    const parentId = await getParentId();
    const studentsData = await getStudentData(parentId);
    setAllStudents(studentsData);
    setLoading(false);
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getData();
      }
    });
  }, []);

  useEffect(() => {
    if (firstLogin) {
      alert('Thank you for signing in. Welcome to ParentU! Here is your first day credit. Please return daily for more credits to gain free rewards.');
      setFirstLogin(false);
    }
  }, [firstLogin]);

  useEffect(() => {
    if (extraReward) {
      alert("You signed in 7 times, congratulations! Extra point granted.");
      setExtraReward(false);
    }
  }, [extraReward]);
 
  
  useEffect(() => {
    if (campuses.length <= 0) getCampusesData(setCampuses);
  }, []);

  useEffect(() => {
    allEventsFiltered.length && addEventsToCalendar(allEventsFiltered, setMark);
    !allEventsFiltered.length && setMark([]);
  }, [allEventsFiltered]);

  useEffect(() => {
    // console.log("FILTER VALUES", filterValues);
    allStudents.length && filterValues.students && filterData('students');
    allEvents.length && filterValues.categories && filterData('categories');
    if (!filterValues.students && allStudents.length) {
      let studentOptions = {};
      allStudents.map((student) => {
        let key = `${student.studentFirstName}_${student.studentLastName}`;
        return studentOptions[key] = true;
      });
      // console.log('studentOptions', studentOptions);
      setFilterValues(((prevState) => ({ ...prevState, students: studentOptions })));
    };
    if (!filterValues.schools && allStudents.length) {
      let schoolList = [];
      let schoolOptions = {};
      allStudents.map((student) => {
        let school = student.studentSchool;
        return schoolList.push(school);
      });
      const uniqueSchools = [...new Set(schoolList)];
      uniqueSchools.map((school) => {
        return schoolOptions[school] = true;
      });
      setFilterValues(((prevState) => ({ ...prevState, schools: schoolOptions })));
    };
    // console.log("CATEGORY", allEvents);
    if (!filterValues.categories && allEvents.length) {
      let eventOptions = {};
      allEvents.map((event) => {
        let key = event.eventCategory;
        // console.log("CATEGORY", key);
        return eventOptions[key] = true;
      });
      setFilterValues(((prevState) => ({ ...prevState, categories: eventOptions })));
    };
  }, [allStudents, allEvents, futureEvents, filterValues]);

  //HANDLING EXPORTS
  const value = {
    campuses,
    students,
    setStudents,
    rewards,
    notes,
    setNotes,
    setRewards,
    redemptions,
    creditHistory,
    mobileMenuOpen,
    setMobileMenuOpen,
    mobileFilterOpen,
    setMobileFilterOpen,
    setFutureEvents,
    onRedeem,
    locale,
    setLocale,
    navList,
    titles,
    language,
    setLanguage,
    signInEmail,
    setSignInEmail,
    parent,
    setParent,
    mark,
    getData,
    userLocation,
    addEventsToCalendar,
    filterValues,
    setFilterValues,
    allStudents,
    allEvents, allEventsFiltered,
    futureEvents, futureEventsFiltered,
    loading,
    isAllEventsSet,
    arrayEvents,
    setTrue,
    showRewards, 
    setShowRewards

  };

  if (error) {
    throw error; // This will be caught by the nearest ErrorBoundary
  }
  
  return (
    <Context.Provider value={value}>
      {!loading && children}
    </Context.Provider>
  );
};
