import { getStudentsOnEvent } from ".";

export const getAllEventsWithStudents = (events, students) => {
  const eventsWithStudents = []
  
  for (let event of events) {
      let studentsOnEvent = getStudentsOnEvent(event, students)
      if(studentsOnEvent.length) {
          eventsWithStudents.push(event)
      }
  }
  return eventsWithStudents;
};