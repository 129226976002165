import { db } from "@database";
import { collection, getDocs, query } from "firebase/firestore";

export const getStudentData = async (parentId) => {
  const colorsArray = ["#448F99", "#9114b8", "#73bbff", "#a1e6b3", "#e6e4a1", "#FFBA49", "#e6c4a1", "#b894d4", "#d494cf", "#e8b4ac", "#A36500", "#accae8", "#9496d4", "#d993b6", "#9de0a9", "#d993a1", "#826dc2", "#d99393", "#f58484", "#9ed5e6", "#9aa7ab"]
  
  try {
    const studentsQuery = query(collection( db, `parents/${parentId}/students` )); //rewards page won't work if this isn't linked correctly
    const studentsSnapshot = await getDocs(studentsQuery);
    let studentsArray = [];
    studentsSnapshot.docs.forEach((doc, idx) => {
      const student = {
        studentId: doc.data().studentId,
        studentFirstName: doc.data().firstName,
        studentLastName: doc.data().lastName,
        studentSchool: doc.data().school,
        studentCredits: doc.data().studentCredits ?? 0,
        gradeLevel: doc.data().gradeLevel,
        color: colorsArray[idx],
      } 
      studentsArray.push(student)
    });
    return studentsArray;
  } catch (error) {
    console.log("ERROR GETTING STUDENT DATA", error)
  }
};