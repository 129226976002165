import { AppLinks, Events, Rewards, Default } from './card-content'
import desktopStyles from './desktopStyles';
import tabletStyles from './tabletStyles';

export default function index({type=null, cardSize="desktop"}) {
  const styles = {desktop: desktopStyles, tablet: tabletStyles}

    const renderCardContent = () => {
        switch (type) {
            case "AppLinks":
              return <AppLinks cardSize={cardSize} styles={styles[cardSize]}/>
            case "Events":
              return <Events cardSize={cardSize} styles={styles[cardSize]}/>
            case "Rewards":
              return <Rewards cardSize={cardSize} styles={styles[cardSize]}/>
            default:
              return <Default cardSize={cardSize} styles={styles[cardSize]}/>
        }   
    }

    return (
        renderCardContent()
    )
}
