export const getStudentsOnEvent = (event, students) => {
  const studentsOnEvent = []

  if (!event) {
    console.error("Event is undefined");
    return studentsOnEvent;
  }
  
  students && students.forEach((student) => {
      if(student.studentSchool === event.school || event.school === 'Select All') {
          studentsOnEvent.push(student)
      }
  })
  return studentsOnEvent;
};