import { checkIfEventIsToday, formatDate } from "@functions";
import translate from "@translate";
import { useNavigate } from "react-router-dom";
import styles from './styles';

export default function EventCard({ event }) {
    const navigate = useNavigate();
    return (
        <div style={{marginBottom: 20, cursor: "pointer"}} onClick={() => navigate(`/home/event-details/${event.id}`)}>
            <div style={styles.imageContainer}>
                <img style={styles.eventImage} src={event.image} alt="fireSpot"/>
            </div>
            <div style={styles.eventTextContainer}>
                <div>
                    <p style={styles.eventDate}>{checkIfEventIsToday(event) ? "TODAY" : formatDate(event.dateString)}</p>
                    <div>
                        <p style={styles.eventName}>{event.name.toUpperCase()}</p>
                        <p style={styles.eventSchool}>{event.school === 'Select All' ? 'All Schools' : event.school.toUpperCase()}</p>
                    </div>
                </div>
                {/* <p style={styles.eventTime}><strong>{translate("start time")}</strong>{`: ${event.startTime}`}</p>
                <p style={styles.eventTime}><strong>{translate("end time")}</strong>{`: ${event.endTime}`}</p> */}
                <p style={styles.eventCredits}>{`${event.reward} `}<strong>{translate("credits")}</strong></p>
            </div>
        </div>
    )
}
