export default {
  mainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    //   height: "100vh",
  },
  footerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",
      height: "25%",
      // backgroundColor: "green",
      paddingBottom: 20,
  },
  formContainer: {
      display: "flex", 
      alignItems: "flex-end",
      height: "75%",
      // backgroundColor: "red"
  },
  logo: {
      width: 225,
      height: 225
  }
}