import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import translate from '../../i18n/translate';
import styles from './styles';

export const LoginForm = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    if(emailError) {
      console.log("email error - ", emailError);
    }
  }, [emailError]);

  useEffect(() => {
    if (passwordError) {
      console.log("password error - ", passwordError);
    }
  }, [passwordError]);

  const onSignin = (e) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        //redirects the qrcode from LISD Admin
        const params = new URLSearchParams(location.search);
        const redirectUrl = params.get('redirect');

        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          navigate('/home/dashboard');
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        configureErrorMessage(errorCode);
      });
  };

  const configureErrorMessage = (error) => {
    const message = parseError(error);

    if(message.includes("password")) {
      setPasswordError(message);
    } else {
      setEmailError(message);
    }
  };

  const parseError = (error) => {
    const errorSubstring = error.substring(error.indexOf("/") + 1);
    return errorSubstring.replace(/-/g, " ");
  };

  return (
    <div style={styles.mainContainer}>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '30ch' },
        }}
        noValidate
        autoComplete="off"
        style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: 20 }}
      >
        <Typography variant="h6" gutterBottom component="div" color="secondary" style={{ display: "flex", justifyContent: "center" }}>
          {translate('login')}
        </Typography>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            {emailError ? (
              <TextField
                error
                fullWidth
                id="outlined-error"
                label="Error"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                helperText={emailError}
                style={{ marginBottom: 10 }}
              />
            ) : (
              <TextField
                fullWidth
                id="outlined-basic"
                label={translate('email')}
                variant="outlined"
                color="secondary"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                style={{ marginBottom: 10 }}
              />
            )}
            {passwordError ? (
              <TextField
                error
                fullWidth
                id="outlined-error"
                label="Error"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                helperText={passwordError}
                style={{ marginBottom: 20 }}
              />
            ) : (
              <TextField
                fullWidth
                id="outlined-basic"
                label={translate('password')}
                type="password"
                variant="outlined"
                color="secondary"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                style={{ marginBottom: 20 }}
              />
            )}
          </div>
          <Button style={{ marginBottom: 20 }} variant="contained" type="submit" onClick={(e) => onSignin(e)}>
            {translate('login')}
          </Button>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <Link href="/forgot-password" underline="none" color="secondary">
              {translate('forgotpassword')}?
            </Link>
          </div>
        </div>
      </Box>
    </div>
  );
};
